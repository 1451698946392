import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import './loading.css';

export default ({ small = false, backgroundColor = false }) => {
    return (
        <Box
            sx={{
                backgroundColor: backgroundColor ? 'transparent' : '#202141',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: small ? 'auto' : '100vh',
            }}
        >
            <Container component="main" maxWidth="sm">
                {!small && (
                    <div className="logo">
                        <div className="title">
                            <Typography sx={{ fontSize: '4rem', fontWeight: 'bold' }}>EMBAPEL</Typography>
                        </div>
                    </div>
                )}
                <div className='container-loading'>
                    <div className='loader'>
                        <div className='loader--dot'></div>
                        <div className='loader--dot'></div>
                        <div className='loader--dot'></div>
                        <div className='loader--dot'></div>
                    </div>
                </div>
            </Container>
        </Box>
    );
};