import { Box, Container, IconButton, Typography } from "@mui/material";
import { useRef, useState } from 'react';
import { ImDownload } from "react-icons/im";
import { IoMdArrowRoundBack, IoMdArrowRoundForward } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { Controller } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import LayoutDefault from "../../components/Layout/LayoutDefault";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './index.css';

const catalogImgs = new Array(50).fill(0).map((_, i) => i + 1);

export default function Home() {
    const swiperRef = useRef();

    const [index, setIndex] = useState(1);
    const [controlledSwiper, setControlledSwiper] = useState(null);

    const handleNext = () => {
        swiperRef.current.slideNext()
    };

    const handlePrev = () => {
        swiperRef.current.slidePrev()
    };

    const onButtonClick = () => {
        const pdfUrl = "/assets/catalogs/catalogo.pdf";
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "Catálogo Embapel.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <LayoutDefault>
            <Container maxWidth="md">
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box sx={{  width: '100%' }}>
                        <Box sx={{ paddingTop: 3, paddingLeft: 3, paddingRight: 3, paddingBottom: { xs: 0, sm: 1 }, backgroundColor: '#373c78', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderTopLeftRadius: 12, borderTopRightRadius: 12 }}>
                            <Box>
                                <Typography sx={{ color: 'white' }}>CATÁLOGO</Typography>
                            </Box>
                            <Box>
                                <Typography sx={{ color: 'white' }}>DESCARTÁVEIS</Typography>
                                <Typography sx={{ color: 'white' }}>E EMBALAGENS</Typography>
                            </Box>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'sticky', zIndex: 2, top: '100px', backgroundColor: '#373c78', padding: '12px 12px 12px 12px' }}>
                            <Box>
                                <IconButton onClick={onButtonClick}>
                                    <ImDownload />
                                </IconButton>

                                <IconButton disabled={true}>
                                    <MdEmail />
                                </IconButton>
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
                                <IconButton onClick={handlePrev}>
                                    <IoMdArrowRoundBack/>
                                </IconButton>
                                
                                <Typography variant="h6" sx={{ color: 'white' }}>{index}/{catalogImgs?.length}</Typography>

                                <IconButton onClick={handleNext}>
                                    <IoMdArrowRoundForward/>
                                </IconButton>
                            </Box>
                        </Box>

                        <Swiper
                            spaceBetween={0}
                            slidesPerView={1}
                            grabCursor={true}
                            onSlideChange={(e) => setIndex(e.activeIndex + 1)}
                            onSwiper={(swiper) => swiperRef.current = swiper}
                            modules={[Controller]} controller={{ control: controlledSwiper }}
                        >
                            {catalogImgs.map((img, index) => (
                                <SwiperSlide key={index}>
                                    <img src={`./assets/catalogs/imgs/${img}.jpg`} style={{ width: '100%'}}/>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Box>
                </Box>
            </Container>
        </LayoutDefault>
    );
};