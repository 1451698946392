import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuMaterial from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import { SocialIcon } from 'react-social-icons'
import { MdStore } from "react-icons/md";
import { styled } from '@mui/material/styles';
import { GrCatalog } from "react-icons/gr";
import { useAuth } from '../../contexts/auth';
import { Button, Link, useTheme } from '@mui/material';
import { GrCatalogOption } from "react-icons/gr";
import { BiSolidOffer } from "react-icons/bi";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IoMenuOutline } from "react-icons/io5";
import Logo from '../../assets/logo.png';
import Facebook from '../../assets/social-0.png';
import Instagram from '../../assets/social-3.png';
import { useLocation, useNavigate, useNavigation } from 'react-router-dom';
import CustomDrawer from '../CustomDrawer';

const BootstrapButton = styled(Button)(({ location }) => ({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 12,
    padding: '6px 12px',
    lineHeight: 1.5,
    backgroundColor: (location === 'true') ? '#373c7857' : 'transparent',
    color: (location === 'true') ? '#f1f1f1' : '#888dd1',
    '&:hover': {
        backgroundColor: '#373c7857',
        boxShadow: 'none',
        color: '#f1f1f1'
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#373c78',
        borderColor: '#373c78',
    },
}));

export default function Appbar() {
    const navigate = useNavigate();
    const location = useLocation();

    const theme = useTheme();
    const { Logout, getway, signed, loading } = useAuth();

    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleOpenUserMenu = (event) => setAnchorElUser(event.currentTarget);

    const handleCloseUserMenu = () => setAnchorElUser(null);
   
    const isStores = Boolean(location?.pathname === '/');
    const isOffers = Boolean(location?.pathname === '/ofertas');
    const isCatalog = Boolean(location?.pathname === '/catalogos');

    return (
        <AppBar position="sticky" color='transparent' sx={{ backgroundColor: '#202141', boxShadow: 'none' }}>
            <Container maxWidth="md">
                <Toolbar disableGutters sx={{ height: 100, display: 'flex', justifyContent: 'center', }} >
                    <Box sx={{ flex: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-start', gap: 0.5 }}>
                        <BootstrapButton location={isStores.toString()} variant="text" startIcon={<MdStore />} onClick={() => navigate('/')}>
                            LOJAS
                        </BootstrapButton>

                        {/* <BootstrapButton location={isOffers.toString()} variant="text" startIcon={<BiSolidOffer />}>
                            OFERTAS
                        </BootstrapButton> */}
                        
                        <BootstrapButton location={isCatalog.toString()} variant={'text'} startIcon={<GrCatalogOption />} onClick={() => navigate('/catalogos')}>
                            CATÁLOGOS
                        </BootstrapButton>
                    </Box>

                    <Box sx={{ display: { xs: 'flex', md: 'none' }, position: 'absolute', left: 0 }}>
                        <CustomDrawer/>
                    </Box>

                    <Box 
                        sx={{ 
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center', 
                            width: '170px',
                            position: 'relative',
                            top: '31px',
                            backgroundImage: `Url(${Logo})`, 
                            height: '170px', 
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat', 
                            backgroundSize: 'contain' 
                        }}
                    >
                        <Box sx={{ backgroundColor: 'yellow', width: '140px', height: '140px', borderRadius: 50, cursor: 'pointer', opacity: 0 }}></Box>
                    </Box>
                    
                    {(!signed) && (
                        <Box sx={{ display: { xs: 'none', md: 'flex' }, flexDirection: 'row', justifyContent: 'flex-end', gap: 1, flex: 1 }}>
                            <Link href="https://www.facebook.com/www.embapel3.com.br/?locale=pt_BR" target='_blank' underline="none">
                                <Box 
                                    sx={{ 
                                        display: { xs: 'flex' }, 
                                        backgroundImage: `Url(${Facebook})`, 
                                        height: '35px', 
                                        width: '35px', 
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat', 
                                        backgroundSize: 'contain' 
                                    }}
                                />
                            </Link>

                            <Link href="https://www.instagram.com/embapel.distribuidora/" target='_blank' underline="none">
                                <Box 
                                    sx={{ 
                                        display: { xs: 'flex' }, 
                                        backgroundImage: `Url(${Instagram})`, 
                                        height: '35px', 
                                        width: '35px', 
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat', 
                                        backgroundSize: 'contain' 
                                    }}
                                />
                            </Link>
                       
                        </Box>
                    )}

                    {(signed) && (
                        <Box sx={{ display: { xs: 'none', md: 'flex' }, flexDirection: 'row', justifyContent: 'flex-end', gap: 1, flex: 1 }}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ borderRadius: 4, padding: '4px 10px 4px 4px', gap: 1 }}>
                                    <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" sx={{ backgroundColor: '#aeb1df'}} />
                                    <Box sx={{ display: { xs: 'none', md: 'flex' }, flexDirection: 'column', textAlign: 'left' }}>
                                        <Typography sx={{ fontSize: 12, }}>{getway.nomeUsuario.substring(0, 15).toUpperCase()}</Typography>
                                        <Typography sx={{ fontSize: 10, color: '#aeb1df' }}>{getway?.nomeLoja.substring(0, 15).toUpperCase()}</Typography>
                                    </Box>
                                </IconButton>
                            </Tooltip>
                            <MenuMaterial
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                <MenuItem onClick={() => navigate('/pagonet')}>
                                    <Typography textAlign="center">Pagonet</Typography>
                                </MenuItem>
                                <MenuItem onClick={Logout}>
                                    <Typography textAlign="center">Sair</Typography>
                                </MenuItem>
                            </MenuMaterial>
                     
                        </Box>
                    )}
                </Toolbar>
            </Container>
        </AppBar>
    );
};