import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { useAuth } from '../contexts/auth';

import Catalogs from '../pages/Catalogs';
import Login from '../pages/Login';
import Pagonet from '../pages/Pagonet';
import Stores from '../pages/Stores';

const RedirectLogin = ({ signed, children }) => {
    if(signed) return <Navigate to="/pagonet" replace />;

    return children;
};

const ProtectedRoute = ({ signed, children }) => {
    if (!signed) {
      return <Navigate to="/entrar" replace />;
    }

    return children;
};

export default function Routers() {
    const { signed } = useAuth();

    return (
        <BrowserRouter>
            <Routes>
                <Route path="*" element={<Navigate to="/" />} />

                <Route path="/" element={<Stores/>}/>
                <Route path="/catalogos" element={<Catalogs/>}/>

                <Route path="/entrar" element={
                    <RedirectLogin signed={signed}>
                        <Login/>
                    </RedirectLogin>
                }/>

                <Route path="/pagonet" element={
                    <ProtectedRoute signed={signed}>
                        <Pagonet/>
                    </ProtectedRoute>
                }/>
            </Routes>
        </BrowserRouter>
    );
};