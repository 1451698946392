import { Box, Container, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import Menu from "../../Appbar";
import Loading from "../../Loading";
import { useAuth } from "../../../contexts/auth";
import Login from "../../../pages/Login";

export default function LayoutAdmin({ children }) {
    const { signed, loading } = useAuth();
    const location = useLocation();
    const isLogin = Boolean(location.pathname === '/entrar');

    if(loading) {
        return (
            <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundColor: '#202141',
                justifyContent: 'space-between'
            }}
        >
            <Menu/>

            <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
           
                <Container maxWidth="xs">
                    <Loading small={true} backgroundColor={false}/>
                </Container>
         
            </Box>

            <Box 
                sx={{ 
                    minHeight: 40, 
                    backgroundColor: '#373c78', 
                    marginTop: '40px', 
                    display: 'flex', 
                    flexDirection: { xs: 'column', sm: 'row'}, 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    padding: 1, 
                    gap: { xs: 0, sm: 1 } 
                }}
            >
                <Typography color="#f6f6f6" sx={{ fontSize: 12 }}>© 2024 Copyright - Embapel Distribuidora</Typography>
                <Typography color="#f6f6f6" sx={{ fontSize: 12 }}>• Todos os direitos reservados</Typography>
                <Typography color="#f6f6f6" sx={{ fontSize: 12 }}>• Politica de privacidade.</Typography>
            </Box>
        </Box>

        )
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundColor: '#202141',
                justifyContent: 'space-between'
            }}
        >
            <Menu signed/>

            <Box sx={{ flex: 1, display: 'flex', alignItems: isLogin ? 'center' : 'flex-start', marginTop: 6 }}>
                {children}
            </Box>

            <Box 
                sx={{ 
                    minHeight: 40, 
                    backgroundColor: '#373c78', 
                    marginTop: '40px', 
                    display: 'flex', 
                    flexDirection: { xs: 'column', sm: 'row'}, 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    padding: 1, 
                    gap: { xs: 0, sm: 1 } 
                }}
            >
                <Typography color="#f6f6f6" sx={{ fontSize: 12 }}>© 2024 Copyright - Embapel Distribuidora</Typography>
                <Typography color="#f6f6f6" sx={{ fontSize: 12 }}>• Todos os direitos reservados</Typography>
                <Typography color="#f6f6f6" sx={{ fontSize: 12 }}>• Politica de privacidade.</Typography>
            </Box>
        </Box>
    );
};