import React from 'react';
import './App.css';

import { AuthProvider } from './contexts/auth'

import Theme from './theme';

import Routers from './routers';

const App = () => {
    return (
        <Theme>
            <AuthProvider>
                <Routers />
            </AuthProvider>
        </Theme>
    );
};

export default App;