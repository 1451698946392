import React, { createContext, useContext, useEffect, useState } from 'react';
import { Box, Backdrop, CircularProgress, Container, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Swal from 'sweetalert2'

import Loading from '../../components/Loading';


import Getway from '../../api/Getway';

const AuthContext = createContext({});

export function useAuth(){
    const context = useContext(AuthContext);
    return context;
}

const idPermissions = ['1', '241', '397'];

const infoPermissions = (id) => {
    const description = {
        '1': 'O usuário não tem permissão de [Produtos] em Cadastro, entre em contato com suporte.',
        '241': 'O usuário não tem permissão de [Produtos] em Cadastro, entre em contato com suporte.',
        '397': 'O usuário não tem permissão de [Acesso ao Módulo Cadastro (Core)] em Cadastro, entre em contato com suporte.'
    };

    return description[String(id)];
};

export const AuthProvider = ({ children }) => {
    const theme = useTheme();

    const [loading, setLoading] = useState(true);
    const [backdrop, setBackdrop] = useState(false);

    const [getway, setGetway] = useState(null);
    const [report, setReport] = useState(null);

    const [getwayStore, setGetwayStore] = useState(null);
    const [getwayStores, setGetwayStores] = useState([]);
    const [getwayAllStores, setGetwayAllStores] = useState([]);
    const [permissions, setPermissions] = useState({ insert: false, edit: false, delete: false });

    const handleCheckLogin = async(data) => {
        
        const dataStore = data.parametros.filter(store => store.lojaId === data.codLoja)[0];
        const dataStores = data.parametros.filter(store => store.cnpj !== dataStore.cnpj);

        if(!Object.keys(data.permissoes).length) throw { message: `O usuário não tem permissões para acessar a plataforma, verifique as permissões no Samb@net` };

        const checkPermissions = idPermissions.filter(x => !Object.keys(data.permissoes).includes(x));
        if(checkPermissions.length) throw { message: infoPermissions(checkPermissions[0]) };

        //Verificar se tem permissão para acessar outras lojas
        const checkPermissionStore = data.permissoes[397].split('@');
        const checkAllPermissionStore = Number(checkPermissionStore[1].replace(/\D/g, '')) === 0;
        if(!checkAllPermissionStore) {
            checkPermissionStore.shift();
            const permissionCodLoja = checkPermissionStore.map(x => Number(x.replace(/\D/g, '')));
            
            if(!permissionCodLoja.includes(data.codLoja)) throw { message: 'O usuário não tem permissão de acesso para esse estabelecimento, verifique as permissões por loja no Samb@net.' };
        };

        // Verificar no Controle de Pedido as permissões para criar, deletar e editar
        const authorization = data.permissoes[241];
        if(authorization) {
            const arrayAuthorization = authorization.split('@')[0].split('%').slice(1);
            const module = {
                insert: arrayAuthorization.includes('1'),
                editable: arrayAuthorization.includes('2'),
                delete: arrayAuthorization.includes('3')
            };

            setPermissions(module);
        };

        setGetwayStore(dataStore); //Apenas a loja logada
        setGetwayStores(dataStores); //Todas as lojas exceto a loja logada
        setGetwayAllStores(data.parametros); //Todas as lojas
        setGetway(data); //Config da Getway

        localStorage.setItem('getway', JSON.stringify(data));
    };

    const Login = async(params) => {
        try {
            const { data } = await Getway.login(params);

            await handleCheckLogin(data);
        } catch(err) {
            console.log('AuthContext, login:', err.debugger || err.message);

            Swal.fire({
                title: `<label style="font-size:18px;font-family:Muli">${'Atenção'}</label>`,
                html: `<label style="font-size:14px;font-family:Muli">${err.message}</label>`,
                icon: 'error',
                width: '20em',
                confirmButtonColor: theme.palette.primary.main,
                confirmButtonText: `<label style="font-size:14px;font-family:Muli">CONCLUIR</label>`
            });
        };
    };

    const Logout = () => {
        setGetwayStore(null);
        setGetwayStores([]);
        setGetwayAllStores([]);
        setGetway(null);
        setReport(null);

        localStorage.removeItem('getway');
    };
    
    useEffect(() => {
        (async() => {
            try {
                await new Promise(resolve => setInterval(resolve, 2000));
                const paramsGetway = localStorage.getItem('getway');
                if(paramsGetway !== null && paramsGetway !== undefined) {
                    const getway = JSON.parse(paramsGetway);

                    const { status, data } = await Getway.refreshLogin(getway.token);
                    if(status) {
                        await handleCheckLogin(data);
                    };
                };
            } catch(err) {
                console.log('HandleRefreshToken:', err.debugger || err.message);
            } finally {
                setLoading(false);
            };
        })();
    }, []);

    // if(loading) {
    //     return (
    //         <Loading/>
    //     )
    // };

    return (
        <AuthContext.Provider
            value={{
                loading,
                signed: Boolean(getwayStore),
                getwayStore,
                getwayStores,
                getwayAllStores,
                permissions,
                report,
                getway,
                backdrop,
                setBackdrop,
                Login,
                Logout
            }}
        >
            <Backdrop
                sx={{ backgroundColor: 'rgb(0 0 0 / 60%)', color: '#fff', zIndex: 10000, display: 'flex', flexDirection: 'column' }}
                open={backdrop}
            >
                <div className='container-loading'>
                    <div className='loader'>
                        <div className='loader--dot'></div>
                        <div className='loader--dot'></div>
                        <div className='loader--dot'></div>
                        <div className='loader--dot'></div>
                        <div className='loader--dot'></div>
                    </div>
                </div>
            </Backdrop>

            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;