import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Avatar, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles'
import { IoMenuOutline } from "react-icons/io5";
import { MdStore } from "react-icons/md";
import { GrCatalogOption } from "react-icons/gr";
import { BiSolidOffer } from "react-icons/bi";
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { IoLogInOutline } from "react-icons/io5";

const CustomListItemButton = styled(ListItemButton)(({ location, logout }) => ({
    color: (location === 'true') || (logout === 'true') ? '#f1f1f1' : '#888dd1',
    backgroundColor: (location === 'true') ? '#373c7857' : 'transparent',
    '&.Mui-disabled': {
        opacity: (location === 'true') ? 1 : 0.38,
    },
    '&:hover': {
        backgroundColor: '#373c78',
        color: '#f1f1f1'
    }
}));


export default function CustomDrawer() {
    const { signed, getway, Logout } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();

    const [open, setOpen] = React.useState(false);

    const isStores = Boolean(location?.pathname === '/');
    const isOffers = Boolean(location?.pathname === '/ofertas');
    const isCatalog = Boolean(location?.pathname === '/catalogos');
    const isPagonet = Boolean(location?.pathname === '/pagonet');

    return (
        <React.Fragment key={'left'}>
            <IconButton
                aria-label="more"
                aria-haspopup="true"
                onClick={() => setOpen(true)}
            >
                <IoMenuOutline size={36} />
            </IconButton>

            <Drawer
                anchor={'left'}
                open={open}
                onClose={() => setOpen(false)}
                PaperProps={{ style: { backgroundColor: '#373c78', justifyContent: 'space-between' } }}
            >
   
                <Box
                    sx={{ width: 250, backgroundColor: '#373c78' }}
                    role="presentation"
                    // onClick={() => setOpen(false)}
                    onKeyDown={() => setOpen(false)}
                >
                    <List sx={{ backgroundColor: '#202141', boxShadow: '0px -8px 4px 10px #000000b8' }}>
                        {signed && (
                            <Box sx={{ padding: 2, paddingTop: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
                                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" sx={{ backgroundColor: '#aeb1df'}} />
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column', textAlign: 'left' }}>
                                    <Typography sx={{ fontSize: 14, }}>{getway.nomeUsuario.substring(0, 15).toUpperCase()}</Typography>
                                    <Typography sx={{ fontSize: 12, color: '#aeb1df' }}>{getway?.nomeLoja.substring(0, 15).toUpperCase()}</Typography>
                                </Box>
                            </Box>
                        )}

                        <ListItem disablePadding>
                            <CustomListItemButton onClick={() => navigate('/')} disabled={isStores} location={isStores?.toString()}>
                                <ListItemIcon sx={{ minWidth: 32 }}>
                                    <MdStore color={isStores ? '#f1f1f1' : '#888dd1'} size={20}/>
                                </ListItemIcon>

                                <ListItemText primary={'LOJAS'} />
                            </CustomListItemButton>
                        </ListItem>
                        
                        {/* <ListItem disablePadding>
                            <CustomListItemButton onClick={() => navigate('/ofertas')} disabled={isOffers} location={isOffers?.toString()}>
                                <ListItemIcon sx={{ minWidth: 32 }}>
                                    <BiSolidOffer color={isOffers ? '#f1f1f1' : '#888dd1'}/>
                                </ListItemIcon>

                                <ListItemText primary={'OFERTAS'} />
                            </CustomListItemButton>
                        </ListItem> */}
                        
                        <ListItem disablePadding>
                            <CustomListItemButton onClick={() => navigate('/catalogos')} disabled={isCatalog} location={isCatalog?.toString()}>
                                <ListItemIcon sx={{ minWidth: 32 }}>
                                    <GrCatalogOption color={isCatalog ? '#f1f1f1' : '#888dd1'} size={20}/>
                                </ListItemIcon>

                                <ListItemText primary={'CATÁLOGOS'} />
                            </CustomListItemButton>
                        </ListItem>
                        {signed && (
                            <ListItem disablePadding>
                                <CustomListItemButton onClick={() => navigate('/pagonet')} disabled={isPagonet} location={isPagonet?.toString()}>
                                    <ListItemIcon sx={{ minWidth: 32 }}>
                                        <GrCatalogOption color={isPagonet ? '#f1f1f1' : '#888dd1'} size={20}/>
                                    </ListItemIcon>

                                    <ListItemText primary={'PAGONET'} />
                                </CustomListItemButton>
                            </ListItem>
                        )}
                    </List>
                </Box>
         
                {signed && (
                    <ListItem disablePadding>
                        <CustomListItemButton onClick={() => Logout()} logout={'true'} >
                            <ListItemIcon sx={{ minWidth: 32 }}>
                                <IoLogInOutline color={'#f1f1f1'} size={20}/>
                            </ListItemIcon>

                            <ListItemText primary={'SAIR'} />
                        </CustomListItemButton>
                    </ListItem>
                )}
            </Drawer>
        </React.Fragment>
    );
};