
import axios from 'axios';

const api = axios.create({ 
    baseURL: process.env.REACT_APP_STAGE === 'development' ? process.env.REACT_APP_DEV_API_SERVER : process.env.REACT_APP_API_SERVER,
});

// console.log('API Server0:', process.env.REACT_APP_STAGE === 'development' ? process.env.REACT_APP_DEV_API_SERVER : process.env.REACT_APP_API_SERVER);
//eslint-disable-next-line
export default {
    getProduct: (params) => {
        return new Promise(async(resolve, reject) => {
            await api.get(`/produto/${params?.prodId}`, params, { timeout: 10000 })
            .then(response => resolve(response))
            .catch(err => reject({ message: 'Não foi possível consultar produto, entre em contato com suporte do aplicativo!', debugger: err.response }));
        });
    },
    setProduct: (params) => {
        return new Promise(async(resolve, reject) => {
            await api.post('/produto', params, { timeout: 10000 })
            .then(response => resolve(response))
            .catch(err => reject({ message: 'Não foi possível salvar o produto, entre em contato com suporte do aplicativo!', debugger: err.response }));
        });
    },
    updateProduct: (params) => {
        return new Promise(async(resolve, reject) => {
            await api.put(`/produto/${params?.prodId}`, params, { timeout: 10000 })
            .then(response => resolve(response))
            .catch(err => reject({ message: 'Não foi possível atualizar o produto, entre em contato com suporte do aplicativo!', debugger: err.response }));
        });
    },

    getOrder: (params) => {
        return new Promise(async(resolve, reject) => {
            await api.get(`/pedido/${params?.cnpj}/${params?.pedidoId}`, params, { timeout: 10000 })
            .then(response => resolve(response))
            .catch(err => reject({ message: 'Não foi possível consultar pedido, entre em contato com suporte do aplicativo!', debugger: err.response }));
        });
    },
    getOrderPending: (params) => {
        return new Promise(async(resolve, reject) => {
            await api.get(`/pedido/${params?.cnpj}`, { timeout: 10000 })
            .then(response => resolve(response))
            .catch(err => reject({ message: 'Não foi possível consultar pedido, entre em contato com suporte do aplicativo!', debugger: err.response }));
        });
    },
    setOrder: (params) => {
        return new Promise(async(resolve, reject) => {
            await api.post('/pedido', params, { timeout: 10000 })
            .then(response => resolve(response))
            .catch(err => reject({ message: 'Não foi possível salvar o pedido, entre em contato com suporte do aplicativo!', debugger: err.response }));
        });
    },
    updateOrder: (params) => {
        return new Promise(async(resolve, reject) => {
            await api.put(`/pedido/${params?.cnpj}/${params?.pedidoId}`, params, { timeout: 10000 })
            .then(response => resolve(response))
            .catch(err => reject({ message: 'Não foi possível atualizar o pedido, entre em contato com suporte do aplicativo!', debugger: err.response }));
        });
    }
};