import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            main: '#2d323e',
        },
        primary: {
            main: '#42A5F5',
        }
    },
    custom: {
        background: {
            header: '#262933',
            iconLogin: '#b9b9b9',
        },
        color: {
            buttonLogin: '#fff',
            confirm: '#42a5f5',
            cancel: '#c56969'
        },
    },
    typography: {
        fontFamily: [
            'Muli'
        ],
        button: {
            textTransform: 'none',
        }
    },
});

const Theme = ({ children }) => {
    return (
        <ThemeProvider theme={darkTheme}>
            {children}
        </ThemeProvider>
    );
};

export default Theme;