import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TableContainer from '@mui/material/TableContainer';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTheme } from '@mui/material';
import Swal from 'sweetalert2'
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

import Getway from '../../api/Getway';
import Server from '../../api/Server';
import { useAuth } from '../../contexts/auth';
import { CurtainsClosedTwoTone } from '@mui/icons-material';
import { set } from 'date-fns';

const headCells = [
    {
        id: 'pedidoId',
        align: 'left',
        disablePadding: true,
        label: 'ID',
        labelMin: 'ID',
        width: '100px',
        display: 'table-cell'
    },
    {
        id: 'lojaId',
        align: 'left',
        disablePadding: true,
        label: 'EAN',
        labelMin: 'EAN',
        width: '100px',
        display: 'table-cell'
    },
    {
        id: 'razao',
        align: 'left',
        disablePadding: true,
        label: 'Descrição',
        labelMin: 'Descrição',
        width: '400px',
        display: 'table-cell'
    }
];

function HeadCellName({name}) {
    const width = useWidth();
    return (width === 'xs' || width === 'sm' || width === 'md') ? name[1] : name[0];
};

function useWidth() {
    const theme = useTheme();
    const keys = [...theme.breakpoints.keys].reverse();
    return (
        keys.reduce((output, key) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const matches = useMediaQuery(theme.breakpoints.up(key));
            return !output && matches ? key : output;
        }, null) || 'xs'
    );
};

const ButtonFinally = styled(Button)(({ theme }) => ({
    color: '#fff',
    backgroundColor: '#b35656',
    '&:hover': {
        backgroundColor: '#793737',
    },
}));

const ButtonSambanet = styled(LoadingButton)(({ theme }) => ({
    color: '#fff',
    backgroundColor: '#437f46',
    '&:hover': {
        backgroundColor: '#2a5f2d',
    },
}));
//eslint-disable-next-line
export default ({ order, open, openModal, edit, setEdit, setShow, closeModal, openModalImposto, cancel, handleCancel, modalImposto, handleModalOrderOpen }) => {
    const theme = useTheme();
    const { getway, getwayStore } = useAuth();

    const handleClose = () => closeModal();

    const [confirm, setConfirm] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [loading, setLoading] = React.useState([]);
    const [buttonLoading, setButtonLoading] = React.useState(false);
    
    useEffect(() => {
        if(order?.itens) {
            const filterConfirm = order?.itens.filter(x => !cancel.includes(x.prodId) && Boolean(x.fgf[0].new.length));
            if(!filterConfirm.length) return setConfirm(false);
            return setConfirm(true);
        };
    });

    if(modalImposto && edit) setEdit(false);

    const handleConfirm = async() => {        
        try {
            setConfirmLoading(true);

            const filterProducts = order?.itens.filter(x => !cancel.includes(x.prodId) && Boolean(x.fgf[0].new.length));

            for(let i=0;i<filterProducts.length;i++) {
                await new Promise(async resolve => {
                    try {
                        setLoading(prev => [ ...prev, filterProducts[i].prodId ]);
                        let response = (await Getway.getProduct(filterProducts[i].prodId, getway.token)).data;

                        await Server.setProduct({
                            cnpj: getwayStore.cnpj,
                            pedidoId: order?.capa.pedidoId,
                            prodId: response?.id,
                            barra: response?.barraPrincipal,
                            old: JSON.stringify({ estadual: response?.impostosEstaduais, federal: response?.impostosFederais })
                        });

                        const params = {
                            "produtoId": response.id,
                            "capa": {
                                "ativo": true,
                                "barraPrincipal": response.barraPrincipal,
                                "categoriaId": response.categoriaId,
                                "centroReceitaId": response.centroReceitaId,
                                "cestaBasica": response.cestaBasica,
                                "descricao": response.descricao,
                                "etqEmbalagem": response.etqEmbalagem,
                                "familiaId": response.familiaId,
                                "grupoId": response.grupoId,
                                "margemBruta": response.margemBruta,
                                "precoCusto": response.precoCusto,
                                "precoVenda": response.precoVenda,
                                "setorBalancaId": response.setorBalancaId,
                                "unidade": response.unidade,
                                "validadeSetorBalanca": response.validadeSetorBalanca,
                            },
                            "combustivel": response.combustivel ?? {},
                            "dadosAdicionais": response.dadosAdicionais,
                            "dadosComplementares": response.dadosComplementares,
                            "estoques": response.estoques,
                            "formasVenda": response.formasVenda,
                            "formasVendaExcluir": response.formasVendaExcluir,
                            "fornecedores": response.fornecedores,
                            "fornecedoresExcluir": response.fornecedoresExcluir ?? [],
                            "impostosEstaduais": [{
                                "uf": response.impostosEstaduais[0].uf,
                                "tipoImposto": response.impostosEstaduais[0].tipoImposto,
                                "codCstEntrada": response.impostosEstaduais[0].codCstEntrada,
                                "tribEcf": filterProducts[i].fgf[0]?.new[0]?.estadual[0].tribEcf,
                                "redSaidaNf": filterProducts[i].fgf[0]?.new[0]?.estadual[0].redSaidaNf,
                                "valorTrib": filterProducts[i].fgf[0]?.new[0]?.estadual[0].valorTrib,
                                "codProd": response.impostosEstaduais[0].codProd,
                                "codLoja": getwayStore.lojaId,
                                "codCstSaida": filterProducts[i].fgf[0]?.new[0]?.estadual[0].codCstSaida,
                                "tribNfSemRed": filterProducts[i].fgf[0]?.new[0]?.estadual[0].tribNfSemRed,
                                "redEntradaNf": response.impostosEstaduais[0].redEntradaNf,
                                "aliqIbpt": filterProducts[i].fgf[0]?.new[0]?.estadual[0].aliqIbpt,
                                "mvaIva": filterProducts[i].fgf[0]?.new[0]?.estadual[0].mvaIva,
                                "mvaIvaInter": filterProducts[i].fgf[0]?.new[0]?.estadual[0].mvaIvaInter,
                                "validMvaIva": response.impostosEstaduais[0].validMvaIva,
                                "aliqIcmsInt": filterProducts[i].fgf[0]?.new[0]?.estadual[0].aliqIcmsInt,
                                "pauta": response.impostosEstaduais[0].pauta,
                                "validPauta": response.impostosEstaduais[0].validPauta,
                                "itemSped": "00",
                                "codAjE115": response.impostosEstaduais[0].codAjE115,
                                "codDes": filterProducts[i].fgf[0]?.new[0]?.estadual[0].codDes,
                                "aliqBen": filterProducts[i].fgf[0]?.new[0]?.estadual[0].aliqBen,
                                "codBenInc": filterProducts[i].fgf[0]?.new[0]?.estadual[0].codBenInc,
                                "aliqBaseRed": filterProducts[i].fgf[0]?.new[0]?.estadual[0].aliqBaseRed,
                                "ultICMSCred": filterProducts[i].fgf[0]?.new[0]?.estadual[0].ultICMSCred === null ? 0 : filterProducts[i].fgf[0]?.new[0]?.estadual[0].ultICMSCred,
                                "aplicaRedST": filterProducts[i].fgf[0]?.new[0]?.estadual[0].aplicaRedST === null ? 0 : filterProducts[i].fgf[0]?.new[0]?.estadual[0].aplicaRedST,
                                "modalidadeRedST": filterProducts[i].fgf[0]?.new[0]?.estadual[0].modalidadeRedST,
                                "idItemSped": response.impostosEstaduais[0].idItemSped
                            }],
                            "impostosFederais": {
                                "exTipi": response.impostosFederais.exTipi || null,
                                "cstPisCofinsEntrada": response.impostosFederais.cstPisCofinsEntrada,
                                "cstPisCofinsSaida": filterProducts[i].fgf[0]?.new[0]?.federal.cstPisCofinsSaida,
                                "codigoNCM": (filterProducts[i].fgf[0]?.new[0]?.federal.codigoNCM === '' || filterProducts[i].fgf[0]?.new[0]?.federal.codigoNCM === undefined || filterProducts[i].fgf[0]?.new[0]?.federal.codigoNCM === null) ? null : filterProducts[i].fgf[0]?.new[0]?.federal.codigoNCM.replaceAll('.', ''),
                                "naturezaReceita": filterProducts[i].fgf[0]?.new[0]?.federal.naturezaReceita,
                                "aliquotaIBPT": filterProducts[i].fgf[0]?.new[0]?.federal.aliquotaIBPT === null ? 0 : filterProducts[i].fgf[0]?.new[0]?.aliquotaIBPT,
                                "codigoCEST": (filterProducts[i].fgf[0]?.new[0]?.federal.codigoCEST === '' || filterProducts[i].fgf[0]?.new[0]?.federal.codigoCEST === undefined || filterProducts[i].fgf[0]?.new[0]?.federal.codigoCEST === null) ? null : filterProducts[i].fgf[0]?.new[0]?.federal.codigoCEST.replaceAll('.', '')
                            },
                        };

                        await Getway.updateProduct(params, getway.token);

                        const imposto = {
                            "estadual": params.impostosEstaduais,
                            "federal": params.impostosFederais
                        };

                        await Server.updateProduct({
                            cnpj: getwayStore.cnpj,
                            pedidoId: order?.capa.pedidoId,
                            prodId: response?.id,
                            barra: response?.barraPrincipal,
                            new: JSON.stringify(imposto)
                        });
    
                        await new Promise(resolve => setTimeout(resolve, 1000));
                        setLoading(prev => [ ...prev.filter(x => x !== filterProducts[i].prodId )]);
                        
                    } catch(err) {
                        console.log('Modificando imposto no Samb@net:', err.debugger || err.message);
            
                        Swal.fire({
                            title: `<label style="font-size:18px;font-family:Muli">${'Atenção'}</label>`,
                            html: `<label style="font-size:14px;font-family:Muli">Falha em salvar informações no Samb@net, caso persista entre em contato com suporte do aplicativo</label>`,
                            icon: 'error',
                            width: '25em',
                            confirmButtonColor: theme.palette.primary.main,
                            confirmButtonText: `<label style="font-size:14px;font-family:Muli">CONCLUIR</label>`
                        });
                    } finally {
                        return resolve();
                    }
                });
            };

            let cancels = [ ...cancel ];
            const filterCancel = order?.itens.filter(x => !Boolean(x.fgf[0].new.length));
            filterCancel.map(x => {
                if(!cancels.includes(x.prodId)) {
                    cancels.push(x.prodId);
                };
            });

            const paramsOrder = {
                cnpj: getwayStore.cnpj,
                pedidoId: order?.capa.pedidoId,
                status: 1,
                cancel: Boolean(cancels.length) ? cancels.join(', ') : '',
                confirm: filterProducts.map(x => x.prodId).join(', ')
            };
            await Server.setOrder(paramsOrder);

            await handleModalOrderOpen(order?.capa.pedidoId);
            
            Swal.fire({
                title: `<label style="font-size:18px;font-family:Muli">${'Atenção'}</label>`,
                html: `<label style="font-size:14px;font-family:Muli">As informações foram salvas com sucesso no Samb@net</label>`,
                icon: 'success',
                width: '20em',
                confirmButtonColor: theme.palette.primary.main,
                confirmButtonText: `<label style="font-size:14px;font-family:Muli">CONCLUIR</label>`
            });
        } catch(err) {
            console.log('Modificando imposto no Samb@net:', err.debugger || err.message);

            Swal.fire({
                title: `<label style="font-size:18px;font-family:Muli">${'Atenção'}</label>`,
                html: `<label style="font-size:14px;font-family:Muli">Falha em salvar informações no Samb@net, caso persista entre em contato com suporte do aplicativo</label>`,
                icon: 'error',
                width: '25em',
                confirmButtonColor: theme.palette.primary.main,
                confirmButtonText: `<label style="font-size:14px;font-family:Muli">CONCLUIR</label>`
            });
        } finally {
            setConfirmLoading(false);
        };
    };

    const handleEdit = (value) => {
        try {
            setButtonLoading(true);
            setShow(false);
            setEdit(true);
            openModalImposto(value);
        } catch(err) {
            console.log('Mostrando informações do produto:', err.debugger || err.message);
        } finally {
            setButtonLoading(false);
        };
    };

    const handleShow = async(value) => {
        try {
            setButtonLoading(true);
            setShow(true);
            await openModalImposto(value);
        } catch(err) {
            console.log('Mostrando informações do produto:', err.debugger || err.message);
        } finally {
            setButtonLoading(false);
        };
    };

    const handleFinally = async() => {
        const filterProducts = order?.itens.filter(x => !cancel.includes(x.prodId) && Boolean(x.fgf[0].new.length));

        let htmlProducts = '';
        for(let i=0;i<filterProducts.length;i++) {
            htmlProducts+=`
            <div style="display:flex;flex-direction:column;border:1px solid #6363632e;padding:4px;border-radius:6px;gap:2px">
                <div style="display:flex;flex-direction:row;justify-content:space-between">
                    <div style="display:flex;flex-direction:row;gap:6px;align-items:center;justify-content:center">
                        <div style="background: #545454;border-radius: 5px;padding: 2px 4px;color: #fff;font-size: 8px;display: flex;justify-content: center;align-items: center;">
                            ID
                        </div>
                        <div style="font-size:10px">${filterProducts[i].prodId}</div>
                    </div>
                    <div style="display:flex;flex-direction:row;gap:6px;align-items:center;justify-content:center">
                        <div style="background: #545454;border-radius: 5px;padding: 2px 4px;color: #fff;font-size: 8px;display: flex;justify-content: center;align-items: center;">
                            EAN
                        </div>
                        <div style="font-size:10px">${filterProducts[i].barra}</div>
                    </div>
                </div>
                <div>${filterProducts[i].descricao}</div>
            </div>
            `
        };

        Swal.fire({
            title: `<label style="font-size:18px;font-family:Muli">${'Atenção'}</label>`,
            html: `
                <div style="font-size:14px;font-family:Muli">
                    <label style="font-size:14px;font-family:Muli">Antes de confirmar as alterações dos impostos dos produtos, verifique a lista abaixo os produtos que serão atualizados, efetuar a tarefa caso esteja correto.</label>
                    <div style="text-align:left;margin-top:10px">
                        <div style="display:flex;flex-direction:column;gap:6px">
                            ${htmlProducts}
                        </div>
                    </div>
                </div>
            `,
            icon: 'warning',
            width: '30em',
            allowOutsideClick: false,
            showCancelButton: true,
            cancelButtonText: `<label style="font-size:14px;font-family:Muli">CANCELAR</label>`,
            cancelButtonColor: theme.custom.color.cancel,
            confirmButtonColor: theme.palette.primary.main,
            confirmButtonText: `<label style="font-size:14px;font-family:Muli">CONFIRMAR</label>`,
        }).then(async(result) => {
            if(result.isConfirmed) {
                await handleConfirmFinally();
                Swal.fire({
                    title: `<label style="font-size:18px;font-family:Muli">${'Atenção'}</label>`,
                    html: `<label style="font-size:14px;font-family:Muli">As informações foram salvas com sucesso no Samb@net</label>`,
                    icon: 'success',
                    width: '20em',
                    confirmButtonColor: theme.palette.primary.main,
                    confirmButtonText: `<label style="font-size:14px;font-family:Muli">CONCLUIR</label>`
                });
            };
        });
    };

    const handleConfirmFinally = async() => {
        try {
            console.log(order?.itens);
            setEdit(true);
            const filterProducts = order?.itens.filter(x => !cancel.includes(x.prodId) && Boolean(x.fgf[0].new.length));
            for(let i=0;i<filterProducts.length;i++) {
                await new Promise(async resolve => {
                    try {
                        setLoading(prev => [ ...prev, filterProducts[i].prodId ]);
                        let response = (await Getway.getProduct(filterProducts[i].prodId, getway.token)).data;

                        const params = {
                            "produtoId": response.id,
                            "capa": {
                                "ativo": true,
                                "barraPrincipal": response.barraPrincipal,
                                "categoriaId": response.categoriaId,
                                "centroReceitaId": response.centroReceitaId,
                                "cestaBasica": response.cestaBasica,
                                "descricao": response.descricao,
                                "etqEmbalagem": response.etqEmbalagem,
                                "familiaId": response.familiaId,
                                "grupoId": response.grupoId,
                                "margemBruta": response.margemBruta,
                                "precoCusto": response.precoCusto,
                                "precoVenda": response.precoVenda,
                                "setorBalancaId": response.setorBalancaId,
                                "unidade": response.unidade,
                                "validadeSetorBalanca": response.validadeSetorBalanca,
                            },
                            "combustivel": response.combustivel ?? {},
                            "dadosAdicionais": response.dadosAdicionais,
                            "dadosComplementares": response.dadosComplementares,
                            "estoques": response.estoques,
                            "formasVenda": response.formasVenda,
                            "formasVendaExcluir": response.formasVendaExcluir,
                            "fornecedores": response.fornecedores,
                            "fornecedoresExcluir": response.fornecedoresExcluir ?? [],
                            "impostosEstaduais": [{
                                "uf": response.impostosEstaduais[0].uf,
                                "tipoImposto": response.impostosEstaduais[0].tipoImposto,
                                "codCstEntrada": response.impostosEstaduais[0].codCstEntrada,
                                "tribEcf": filterProducts[i].fgf[0]?.old[0]?.estadual[0].tribEcf,
                                "redSaidaNf": filterProducts[i].fgf[0]?.old[0]?.estadual[0].redSaidaNf,
                                "valorTrib": filterProducts[i].fgf[0]?.old[0]?.estadual[0].valorTrib,
                                "codProd": response.impostosEstaduais[0].codProd,
                                "codLoja": getwayStore.lojaId,
                                "codCstSaida": filterProducts[i].fgf[0]?.old[0]?.estadual[0].codCstSaida,
                                "tribNfSemRed": filterProducts[i].fgf[0]?.old[0]?.estadual[0].tribNfSemRed,
                                "redEntradaNf": response.impostosEstaduais[0].redEntradaNf,
                                "aliqIbpt": filterProducts[i].fgf[0]?.old[0]?.estadual[0].aliqIbpt,
                                "mvaIva": filterProducts[i].fgf[0]?.old[0]?.estadual[0].mvaIva,
                                "mvaIvaInter": filterProducts[i].fgf[0]?.old[0]?.estadual[0].mvaIvaInter,
                                "validMvaIva": response.impostosEstaduais[0].validMvaIva,
                                "aliqIcmsInt": filterProducts[i].fgf[0]?.old[0]?.estadual[0].aliqIcmsInt,
                                "pauta": response.impostosEstaduais[0].pauta,
                                "validPauta": response.impostosEstaduais[0].validPauta,
                                "itemSped": "00",
                                "codAjE115": response.impostosEstaduais[0].codAjE115,
                                "codDes": filterProducts[i].fgf[0]?.old[0]?.estadual[0].codDes,
                                "aliqBen": filterProducts[i].fgf[0]?.old[0]?.estadual[0].aliqBen,
                                "codBenInc": filterProducts[i].fgf[0]?.old[0]?.estadual[0].codBenInc,
                                "aliqBaseRed": filterProducts[i].fgf[0]?.old[0]?.estadual[0].aliqBaseRed,
                                "ultICMSCred": filterProducts[i].fgf[0]?.old[0]?.estadual[0].ultICMSCred === null ? 0 : filterProducts[i].fgf[0]?.old[0]?.estadual[0].ultICMSCred,
                                "aplicaRedST": filterProducts[i].fgf[0]?.old[0]?.estadual[0].aplicaRedST === null ? 0 : filterProducts[i].fgf[0]?.old[0]?.estadual[0].aplicaRedST,
                                "modalidadeRedST": filterProducts[i].fgf[0]?.old[0]?.estadual[0].modalidadeRedST,
                                "idItemSped": response.impostosEstaduais[0].idItemSped
                            }],
                            "impostosFederais": {
                                "cstPisCofinsEntrada": response.impostosFederais.cstPisCofinsEntrada,
                                "cstPisCofinsSaida": filterProducts[i].fgf[0]?.old[0]?.federal.cstPisCofinsSaida,
                                "codigoNCM": (filterProducts[i].fgf[0]?.old[0]?.federal.codigoNCM === '' || filterProducts[i].fgf[0]?.old[0]?.federal.codigoNCM === undefined || filterProducts[i].fgf[0]?.old[0]?.federal.codigoNCM === null) ? null : filterProducts[i].fgf[0]?.old[0]?.federal.codigoNCM.replaceAll('.', ''),
                                "naturezaReceita": filterProducts[i].fgf[0]?.old[0]?.federal.naturezaReceita,
                                "aliquotaIBPT": filterProducts[i].fgf[0]?.old[0]?.federal.aliquotaIBPT === null ? 0 : filterProducts[i].fgf[0]?.old[0]?.aliquotaIBPT,
                                "codigoCEST": (filterProducts[i].fgf[0]?.old[0]?.federal.codigoCEST === '' || filterProducts[i].fgf[0]?.old[0]?.federal.codigoCEST === undefined || filterProducts[i].fgf[0]?.old[0]?.federal.codigoCEST === null) ? null : filterProducts[i].fgf[0]?.old[0]?.federal.codigoCEST.replaceAll('.', '')
                            },
                        };
    
                        await Getway.updateProduct(params, getway.token);
    
                        await new Promise(resolve => setTimeout(resolve, 1000));
                        setLoading(prev => [ ...prev.filter(x => x !== filterProducts[i].prodId )]);
                    } catch(err) {
                        console.log('Modificando imposto no Samb@net:', err.debugger || err.message);
            
                        Swal.fire({
                            title: `<label style="font-size:18px;font-family:Muli">${'Atenção'}</label>`,
                            html: `<label style="font-size:14px;font-family:Muli">Falha em salvar informações no Samb@net, caso persista entre em contato com suporte do aplicativo</label>`,
                            icon: 'error',
                            width: '25em',
                            confirmButtonColor: theme.palette.primary.main,
                            confirmButtonText: `<label style="font-size:14px;font-family:Muli">CONCLUIR</label>`
                        });
                    } finally {
                        return resolve();
                    }
                });
            };

            const paramsOrder = {
                cnpj: getwayStore.cnpj,
                pedidoId: order?.capa.pedidoId,
                status: 2
            };

            await Server.updateOrder(paramsOrder);

            await handleModalOrderOpen(order?.capa.pedidoId);
        } catch(err) {
            console.log('Modificando imposto no Samb@net:', err.debugger || err.message);

            Swal.fire({
                title: `<label style="font-size:18px;font-family:Muli">${'Atenção'}</label>`,
                html: `<label style="font-size:14px;font-family:Muli">Falha em salvar informações no Samb@net, caso persista entre em contato com suporte do aplicativo</label>`,
                icon: 'error',
                width: '25em',
                confirmButtonColor: theme.palette.primary.main,
                confirmButtonText: `<label style="font-size:14px;font-family:Muli">CONCLUIR</label>`
            });
        } finally {
            setEdit(false);
        };
    };

    return (
        Boolean(Object.keys(order).length) && (
            <React.Fragment>
                <Dialog
                    fullWidth={true}
                    maxWidth={'md'}
                    open={open}
                >
                    <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ textAlign: 'left' }}>
                            <Box sx={{ fontSize: 16 }}>Cliente: {order?.capa?.clienteId} - {order?.capa?.razao}</Box>
                            <Box sx={{ fontSize: 14 }}>Pedido: {order?.capa?.pedidoId}</Box>
                        </Box>
                        <Box sx={{ textAlign: 'right' }}>
                            <Box sx={{ fontSize: 14 }}>Data: {order?.capa?.cadastroFormatado}</Box>
                            <Box sx={{ fontSize: 14 }}>Valor: {order?.capa?.valorFormatado}</Box>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Box sx={{ flexGrow: 1 }}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {headCells.map((headCell) => (
                                                <TableCell
                                                    key={headCell.id}
                                                    align={headCell.align}
                                                    padding={headCell.disablePadding ? 'none' : 'normal'}
                                                    sx={{
                                                        width:headCell.width,
                                                        display:{lg:'table-cell', md:'table-cell', sm:'table-cell', xs:headCell.display},
                                                        fontSize:{lg:'14px', md:'14px', sm:'14px', xs:'10px'}
                                                    }}
                                                >
                                                    <HeadCellName name={[headCell.label, headCell.labelMin]}/>
                                                </TableCell>
                                            ))}
                                            <TableCell sx={{width: '80px'}} key={"acoes"} align="center">Ações</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {order?.itens
                                            .map((row, index) => {
                                                return (
                                                    <TableRow hover tabIndex={-1} key={index} sx={{  }}>
                                                        <TableCell align="left" padding="none" sx={{ backgroundColor: loading.includes(row?.prodId) ? '#00000061' : 'inherit', color: !(!cancel.includes(row?.prodId) && Boolean(row?.fgf[0].new.length)) ? '#7c7c7c' : '#fff', textDecoration: !(!cancel.includes(row?.prodId) && Boolean(row?.fgf[0].new.length)) ? 'line-through' : 'none', fontSize: { lg: '14px', md: '14px', sm: '12px', xs: '10px' }}}>
                                                            {row?.prodId}
                                                        </TableCell>
                                                        <TableCell align="left" padding="none" sx={{ backgroundColor: loading.includes(row?.prodId) ? '#00000061' : 'inherit', color: !(!cancel.includes(row?.prodId) && Boolean(row?.fgf[0].new.length)) ? '#7c7c7c' : '#fff', textDecoration: !(!cancel.includes(row?.prodId) && Boolean(row?.fgf[0].new.length)) ? 'line-through' : 'none', fontSize: { lg: '14px', md: '14px', sm: '12px', xs: '10px' }}}>
                                                            {row?.barra}
                                                        </TableCell>
                                                        <TableCell align="left" padding="none" sx={{ backgroundColor: loading.includes(row?.prodId) ? '#00000061' : 'inherit', color: !(!cancel.includes(row?.prodId) && Boolean(row?.fgf[0].new.length)) ? '#7c7c7c' : '#fff', textDecoration: !(!cancel.includes(row?.prodId) && Boolean(row?.fgf[0].new.length)) ? 'line-through' : 'none', fontSize: { lg: '14px', md: '14px', sm: '12px', xs: '10px' }}}>
                                                            {row?.descricao}
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ position: 'relative', backgroundColor: loading.includes(row?.prodId) ? '#00000061' : 'inherit', padding: "6px 0px !important" }}>
                                                            <Box sx={{ flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                                                                <Box sx={{ flexDirection: 'row', display: loading.includes(row?.prodId) ? 'none' : 'flex', justifyContent: 'flex-end', gap: '4%', paddingRight: 1 }}>
                                                                    <Button disabled={buttonLoading || (cancel.includes(row?.prodId) || !Boolean(row?.fgf[0].new.length))} color="success" onClick={() => handleShow(row)} sx={{ padding: '0px 4px', fontSize: 11 }} variant='outlined' size="small">Original</Button>
                                                                    
                                                                    <Button disabled={buttonLoading || (order?.pedido[0].status === 2) || (cancel.includes(row?.prodId) && Boolean(row?.fgf[0].new.length)) || confirmLoading || edit || (order?.pedido[0].status === 1)} variant='contained' color={Boolean(row?.fgf[0].new.length) ? 'warning' : 'primary'} onClick={() => handleEdit(row)} sx={{ padding: '0px 4px', fontSize: 11 }} size="small">{Boolean(row?.fgf[0].new.length) ? 'Editar' : 'Cadastrar'}</Button>
                                                                    
                                                                    {(!cancel.includes(row?.prodId) && Boolean(row?.fgf[0].new.length)) && (
                                                                        <IconButton disabled={buttonLoading || (order?.pedido[0].status === 2) || (order?.pedido[0].status === 1) || confirmLoading || edit} onClick={() => handleCancel(row.prodId, true)} aria-label="delete" size="small">
                                                                            <CloseIcon fontSize="inherit" sx={{ color: theme.custom.color.cancel }}/>
                                                                        </IconButton>
                                                                    )}

                                                                    {(cancel.includes(row?.prodId) || !Boolean(row?.fgf[0].new.length)) && (
                                                                        <IconButton disabled={buttonLoading || (order?.pedido[0].status === 2) || (order?.pedido[0].status === 1) || !Boolean(row?.fgf[0].new.length) || confirmLoading || edit} sx={{ display: Boolean(row?.fgf[0].new.length) ? 'flex' : 'none' }} onClick={() => handleCancel(row.prodId, false)} aria-label="delete" size="small">
                                                                            <CheckIcon fontSize="inherit" sx={{ color: theme.custom.color.confirm }}/>
                                                                        </IconButton>
                                                                    )}
                                                                    <Box sx={{ width: '31.5px', height: '28px', display: !Boolean(row?.fgf[0].new.length) ? 'flex' : 'none' }}></Box>
                                                                </Box>
                                                                <Box sx={{ height: '28px', display: loading.includes(row?.prodId) ? 'flex' : 'none' }}>
                                                                    <CircularProgress
                                                                        size={24}
                                                                        sx={{
                                                                            position: 'absolute',
                                                                            top: '50%',
                                                                            left: '50%',
                                                                            marginTop: '-12px',
                                                                            marginLeft: '-12px',
                                                                        }}
                                                                    />
                                                                </Box>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ padding: '16px 24px' }}>
                        <Button loading={true} disabled={confirmLoading || edit || (order?.pedido[0].status === 1) || buttonLoading} sx={{ color: '#fff' }} onClick={handleClose}>FECHAR</Button>
                        {(order?.pedido[0].status === 1) && (
                            <ButtonFinally variant="contained" disabled={confirmLoading || edit || !(order?.pedido[0]?.status === 1) || buttonLoading} onClick={handleFinally}>FINALIZAR</ButtonFinally>
                        )}
                        {((order?.pedido[0].status !== 2) && (order?.pedido[0].status !== 1)) && (
                            <ButtonSambanet color="success" loading={confirmLoading} variant="contained" disabled={!confirm || edit || (order?.pedido[0].status === 1) || buttonLoading} onClick={handleConfirm}>SALVAR NO SAMB@NET</ButtonSambanet>
                        )}

                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    );
}