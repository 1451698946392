import React, { useState, memo } from 'react';
import { Visibility, VisibilityOff, Domain, Person } from '@mui/icons-material';
import {
    Box,
    Container,
    InputLabel,
    InputAdornment,
    FormHelperText,
    FormControl,
    OutlinedInput,
    Button,
    IconButton,
    CircularProgress, 
    Typography
} from '@mui/material';
import Swal from 'sweetalert2';

import { useAuth } from '../../contexts/auth';
import { useTheme } from '@mui/material/styles';

import LayoutDefault from '../../components/Layout/LayoutDefault';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading';
import LayoutAdmin from '../../components/Layout/LayoutAdmin';

export default function Login() {
    const { Login, loading, signed } = useAuth();
    const navigate = useNavigate();

    const theme = useTheme();

    const initialParamsLogin = { cnpj: '', usuario: '', senha: '', token: null };
    const [paramsLogin, setParamsLogin] = useState(initialParamsLogin);

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => event.preventDefault();

    const [activeSubmit, setActiveSubmit] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);

    if(paramsLogin.cnpj !== '' && paramsLogin.usuario !== '' && paramsLogin.senha !== '') {  
        if((paramsLogin.cnpj.length === 5 || paramsLogin.cnpj.length === 18)) {
            if(activeSubmit) setActiveSubmit(false);
        } else {
            if(!activeSubmit) setActiveSubmit(true);
        };
    } else {
        if(!activeSubmit) setActiveSubmit(true);
    };

    const handleLogin = async() => {
        try {
            setButtonLoading(true);
            await Login(paramsLogin);
        } catch(err) {
            console.log('Efetuando login no Servidor:', err.debugger || err.message);
            Swal.fire({
                title: `<label style="font-size:18px;font-family:Muli">${'Atenção'}</label>`,
                html: `<label style="font-size:14px;font-family:Muli">${'Não foi possível acessar a plataforma, caso persista entre em contato com suporte do aplicativo'}</label>`,
                icon: 'error',
                width: '30em',
                confirmButtonColor: theme.palette.primary.main,
                confirmButtonText: `<label style="font-size:14px;font-family:Muli">CONCLUIR</label>`
            });
        } finally {
            setButtonLoading(false);
        };
    };
  
    // if(true) {
    //     return (
    //         <Loading/>
    //     )
    // };


    return (
        <LayoutAdmin>
            <Container maxWidth="xs">
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 1.5 }}>
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="outlined-cnpjOrCodLoja">CNPJ ou Código interno</InputLabel>
                        <OutlinedInput
                            value={paramsLogin.cnpj}
                            id="outlined-cnpjOrCodLoja"
                            type='text'
                            endAdornment={
                                <InputAdornment position="end">
                                    <Domain sx={{ color: theme.custom.background.iconLogin }}/>
                                </InputAdornment>
                            }
                            label="CNPJ ou Código interno"
                            onChange={(event) => {
                                let cnpj = event.target.value.replace(/\D/g, '');

                                if(cnpj.length > 5) {
                                    cnpj = cnpj.replace(/^(\d{2})(\d)/, '$1.$2'); // adiciona ponto após os dois primeiros dígitos
                                    cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3'); // adiciona ponto após os próximos três dígitos
                                    cnpj = cnpj.replace(/\.(\d{3})(\d)/, '.$1/$2'); // adiciona barra após os próximos três dígitos
                                    cnpj = cnpj.replace(/(\d{4})(\d)/, '$1-$2');
                                };

                                setParamsLogin((prev) => ({ ...prev, cnpj: cnpj.substring(0, 18) }));
                            }}
                            autoComplete='off'
                        />
                    </FormControl>

                    <FormControl variant="outlined">
                        <InputLabel htmlFor="outlined-username">Usuario</InputLabel>
                        <OutlinedInput
                            value={paramsLogin.usuario}
                            id="outlined-username"
                            type='text'
                            endAdornment={
                                <InputAdornment position="end">
                                    <Person sx={{ color: theme.custom.background.iconLogin }}/>
                                </InputAdornment>
                            }
                            label="Usuario"
                            onChange={(event) => setParamsLogin((prev) => ({ ...prev, usuario: event.target.value }))}
                            inputProps={{ maxLength: 20 }}
                            autoComplete='off'
                        />
                    </FormControl>

                    <FormControl variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
                        <OutlinedInput
                            value={paramsLogin.senha}
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                                <InputAdornment position="end" sx={{ marginRight: '4px' }}>
                                    <IconButton
                                        aria-label="Ação para tornar a senha visível"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff sx={{ color: theme.custom.background.iconLogin }}/> : <Visibility sx={{ color: theme.custom.background.iconLogin }}/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Senha"
                            onChange={(event) => setParamsLogin((prev) => ({ ...prev, senha: event.target.value }))}
                            inputProps={{ maxLength: 20 }}
                            onKeyDown={(event) => (event.key === 'Enter') && handleLogin()}
                        />
                    </FormControl>

                    <FormControl>
                        <Box sx={{ position: 'relative' }}>
                            <Button
                                fullWidth
                                variant="contained"
                                disabled={activeSubmit || buttonLoading}
                                sx={{ height: '60px', color: theme.custom.color.buttonLogin, display: 'flex', justifyContent: 'flex-start' }}
                                onClick={handleLogin}
                            >
                                Entrar
                            </Button>
                            {buttonLoading && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        color: theme.custom.color.buttonLogin,
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )}
                        </Box>
                    </FormControl> 
                </Box>
            </Container>
        </LayoutAdmin>
    );
};
