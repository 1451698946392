import { Box, Button, Container, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { FaMapMarkedAlt } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";

import Banner from '../../assets/banner.png';
import Loja6 from '../../assets/loja6.jpeg';
import Title from '../../assets/title.png';

import { Lojas } from '../../components/Models/lojas';
import LayoutDefault from "../../components/Layout/LayoutDefault";

import './index.css';

const BootstrapButton = styled(Button)(() => ({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 12,
    padding: '6px 12px',
    lineHeight: 1.5,
    backgroundColor: '#db4348',
    color: '#f6f6f6',
    '&:hover': {
        backgroundColor: '#b2494c',
        boxShadow: 'none',
        color: '#f1f1f1'
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#373c78',
        borderColor: '#373c78',
    },
}));

export default function Stores() {
    return (
        <LayoutDefault>
            <Box sx={{  height: 250, backgroundImage: `Url(${Banner})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', marginBottom: 4 }}/>
            <Container maxWidth="md">
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'center'}}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: { xs: 300, sm: 270 }, borderTopLeftRadius: 14, borderTopRightRadius: 14, borderBottomLeftRadius: 14, borderBottomRightRadius: 14 }}>
                        <Typography sx={{ fontSize: '1.2em', color: '#a8aadb', lineHeight: 1.7, letterSpacing: '1px' }}>Com instalações modernas e amplas, repletas de grandes marcas e produtos, elas oferecem o menor preço, todos os dias!</Typography>
                        <Typography sx={{ fontSize: '20px', color: '#f6f6f6', fontWeight: 'bold', letterSpacing: '1px' }}>A rede de Embapel Distribuidora é composta por 7 lojas.</Typography>
                        <Typography sx={{ fontSize: '1.2em', color: '#a8aadb', lineHeight: 1.7, letterSpacing: '1px' }}>Aproveite hoje mesmo todas as novidades e vantagens que a Embapel tem para você.</Typography>
                    </Box>
                    {Lojas?.map((loja, i) => (
                        <Box key={i} sx={{ height: 570, width: { xs: 300, sm: 270 }, backgroundColor: '#373c78', borderTopLeftRadius: 14, borderTopRightRadius: 14, borderBottomLeftRadius: 14, borderBottomRightRadius: 14, boxShadow: '5px 5px 20px 0px rgb(0 0 0 / 50%)' }}>
                            <Box sx={{  height: 185, backgroundImage: `Url(${Loja6})`, borderTopLeftRadius: 14, borderTopRightRadius: 14, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}/>
                            
                            <Box sx={{ padding: '20px 16px 16px 16px', display: 'flex', flexDirection: 'column', gap: 1.5, position: 'relative' }}>
                                <Box
                                    sx={{
                                        padding: '7px 10px 0px 20px',
                                        display: 'inline-block',
                                        backgroundRepeat: 'no-repeat',
                                        // backgroundPosition: 'right center',
                                        backgroundImage: `url(${Title})`,
                                        height: '2.6em',
                                        overflow: 'hidden',
                                        position: 'absolute',
                                        top: -21,
                                        right: 0,
                                        fontFamily: 'Bebas Neue',
                                        color: '#f1f1f1',
                                        fontSize: '24px'
                                    }}
                                >
                                    {loja?.local}
                                </Box>

                                <Box>
                                    <Typography sx={{ fontSize: 14, fontWeight: 'bold', color: '#00b1f2' }}>ENDEREÇO:</Typography>
                                    <Typography sx={{ fontSize: 12, color: '#f1f1f1' }}>{loja?.endereco}</Typography>
                                    <Typography sx={{ fontSize: 12, color: '#f1f1f1' }}>{loja?.regiao}</Typography>
                                    <Typography sx={{ fontSize: 12, color: '#f1f1f1' }}>CEP: {loja?.cep}</Typography>
                                    <BootstrapButton startIcon={<FaMapMarkedAlt/>} variant="contained" size="small" sx={{ marginTop: 1 }}>VER MAPA</BootstrapButton>
                                </Box>

                                <Box>
                                    <Typography sx={{ fontSize: 14, fontWeight: 'bold', color: '#00b1f2' }}>HORÁRIO DE FUNCIONAMENTO:</Typography>
                                    <Typography sx={{ fontSize: 12, color: '#f1f1f1' }}>{loja?.horario}.</Typography>
                                    <Typography sx={{ fontSize: 12, color: '#f1f1f1' }}>{loja?.domingo}</Typography>
                                    <Typography sx={{ fontSize: 12, color: '#f1f1f1' }}>Em feriados, os horários podem ser diferentes</Typography>
                                </Box>

                                <Box>
                                    <Typography sx={{ fontSize: 14, fontWeight: 'bold', color: '#00b1f2' }}>SAC:</Typography>
                                    <Typography sx={{ fontSize: 12, color: '#f1f1f1' }}>Telefone: {loja?.telefone}</Typography>
                                </Box>

                                   
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>

                                    {Boolean(loja?.whatsapp?.length) && (
                                        loja?.whatsapp.map((whatsapp, i) => (
                                            <a key={i} href={whatsapp?.link} target="_blank" rel="noreferrer">
                                                <Button 
                                                    startIcon={<FaWhatsapp/>} 
                                                    fullWidth 
                                                    variant="contained" 
                                                    size="small" 
                                                    sx={{ backgroundColor: '#25d366', color: '#f1f1f1' }}
                                                >
                                                    {whatsapp?.nome}
                                                </Button>
                                            </a>
                                        ))
                                    )}
                                </Box>
       
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Container>
        </LayoutDefault>
    );
};