import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Backdrop from '@mui/material/Backdrop';
import Switch from '@mui/material/Switch';
import { Box, Container, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Autocomplete from '@mui/material/Autocomplete';
import Swal from 'sweetalert2'
import { styled } from '@mui/material/styles';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';

import { useAuth } from '../../contexts/auth';
import { useTheme } from '@mui/material/styles';

import Getway from '../../api/Getway';

import FGF from '../../services/FGF';
import Server from '../../api/Server';

const CstICmsSaidaAutocomplete = styled(Autocomplete)(({ theme }) => ({
    '& .MuiInputBase-root': {
        [theme.breakpoints.up('xs')]: {
            fontSize: '11px',
            paddingBottom:'4px !important',
            paddingRight:'60px !important'
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '16px',
            paddingBottom:'4px !important',
            paddingRight:'60px !important'
        },
    }
}));

const CstPisCofinsAutocomplete = styled(Autocomplete)(({ theme }) => ({
    '& .MuiInputBase-root': {
        [theme.breakpoints.up('xs')]: {
            fontSize: '11px',
            paddingBottom:'4px !important',
            paddingRight:'60px !important'
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '16px',
            paddingBottom:'4px !important',
            paddingRight:'60px !important'
        },
    }
}));

const TributacaoCfe = styled(Autocomplete)(({ theme }) => ({
    '& .MuiInputBase-root': {
        [theme.breakpoints.up('xs')]: {
            fontSize: '11px',
            paddingBottom:'4px !important',
            paddingRight:'60px !important'
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '16px',
            paddingBottom:'4px !important',
            paddingRight:'60px !important'
        },
    }
}));

const TributacaoNfSemReducao = styled(Autocomplete)(({ theme }) => ({
    '& .MuiInputBase-root': {
        [theme.breakpoints.up('xs')]: {
            fontSize: '11px',
            paddingBottom:'4px !important',
            paddingRight:'60px !important'
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '16px',
            paddingBottom:'4px !important',
            paddingRight:'60px !important'
        },
    }
}));

const cstComBeneficio = ['020', '040', '070'];
const cstComCest = ['010', '060', '070'];
const cstComNatureza = ['04', '05', '06', '07', '08', '09']
// const fgf = {"ncm":"10063021","cest":"","pis":6,"cofins":6,"natureza":105,"cst":"040","aliquota":0,"aliquotaFinal":0,"reducao":0,"fcp":0,"codbenef":"RJ801438","codinfo":"RJ91980000","aliquotaDesoneracao":12}
//eslint-disable-next-line
export default ({ product, show, edit, setEdit, open, order, closeModal, loadingImposto, setLoadingImposto, openModalImposto, handleModalOrderOpen }) => {
    const { getway, getwayStore } = useAuth();
    const theme = useTheme();

    const handleClose = () => {
        setCstIcmsSaida('');
        setTributacaoCfe('');
        setTributacaoNfSemReducao('');
        setReducao('');
        setIbpt('');
        setCodigoBeneficio('');
        setCodigoInformativo('');
        setAliqIcmsDesonerado('');
        setNcm('');
        setCest('');
        setNatureza('');
        setCstPisSaida('');

        setCstIcmsSaidaError(false);
        setTributacaoCfeError(false);
        setTributacaoNfSemReducaoError(false);
        setReducaoError(false);
        setIbptError(false);
        setCodigoBeneficioError(false);
        setCodigoInformativoError(false);
        setAliqIcmsDesoneradoError(false);
        setNcmError(false);
        setCestError(false);
        setNaturezaError(false);
        setCstPisSaidaError(false);

        setFgf('');
        setFgfError(false);

        closeModal();
    };

    //FGF
    const [fgf, setFgf] = useState('');
    const [fgfError, setFgfError] = useState(false);
    const handleChangeFgf = (value) => setFgf(value);

    //Autocomplete para busca na API o CST ICMS de Saída
    const [cstIcmsSaida, setCstIcmsSaida] = React.useState('')
    const [cstIcmsSaidaError, setCstIcmsSaidaError] = React.useState(false);
    const [searchCstIcmsSaida, setSearchIcmsSaida] = React.useState('');
    const [optionsCstIcmsSaida, setOptionsCstIcmsSaida] = React.useState([]);
    const [openCstIcmsSaida, setOpenCstIcmsSaida] = React.useState(false);
    const [loadingCstIcmsSaida, setLoadingCstIcmsSaida] = React.useState(false);

    //Pesquisar CST ICMS de Saída
    useEffect(() => {
        (async() => {
            if(searchCstIcmsSaida !== '' && searchCstIcmsSaida !== cstIcmsSaida.titulo){
                setLoadingCstIcmsSaida(true);

                const params = {
                    "tipo": "cstsaida",
                    "palavraChave": searchCstIcmsSaida,
                    "buscaExataPorCodigo": false
                }

                await Getway.search(params, getway.token)
                .then(response => {
                    if(response.data.itens.length > 0) {
                        setOptionsCstIcmsSaida(response.data.itens)
                        
                    } else {
                        setOptionsCstIcmsSaida([]);
                    };
                })
                .catch(err => {
                    setCstIcmsSaidaError("Não foi possível completar sua busca, tente novamente mais tarde!");
                })

                setLoadingCstIcmsSaida(false);
            };
        })();
    }, [searchCstIcmsSaida]);

    useEffect(() => {
        (async() => {
            if(cstIcmsSaida !== '' && cstIcmsSaida !== undefined) {
                if(cstIcmsSaida?.id.replaceAll(' ', '') === '060') {
                    setTributacaoCfe({id: 'F ', titulo: 'F  - SUBST TRIBUTARIA', descricao: null, unidade: null});
                    setTributacaoNfSemReducao({id: 'F ', titulo: 'F  - SUBST TRIBUTARIA', descricao: null, unidade: null})
                };

                if(cstIcmsSaida?.id.replaceAll(' ', '') === '040') {
                    setTributacaoCfe({id: 'I ', titulo: 'I  - ISENTO', descricao: null, unidade: null});
                    setTributacaoNfSemReducao({id: 'I ', titulo: 'I  - ISENTO', descricao: null, unidade: null});
                };
            };
        })();
    }, [cstIcmsSaida]);

    //Autocomplete para busca na API a Tributação CFE
    const [tributacaoCfe, setTributacaoCfe] = React.useState('')
    const [tributacaoCfeError, setTributacaoCfeError] = React.useState(false);
    const [searchTributacaoCfe, setSearchTributacaoCfe] = React.useState('');
    const [optionsTributacaoCfe, setOptionsTributacaoCfe] = React.useState([]);
    const [openTributacaoCfe, setOpenTributacaoCfe] = React.useState(false);
    const [loadingTributacaoCfe, setLoadingTributacaoCfe] = React.useState(false);

    //Pesquisar Tributação CFE
    useEffect(() => {
        (async() => {
            if(searchTributacaoCfe !== '' && searchTributacaoCfe !== tributacaoCfe.titulo){
                setLoadingTributacaoCfe(true);

                const params = {
                    "tipo": "aliquotaicms",
                    "palavraChave": searchTributacaoCfe,
                    "buscaExataPorCodigo": false
                }

                await Getway.search(params, getway.token)
                .then(response => {
                    (response.data.itens.length > 0) ? setOptionsTributacaoCfe(response.data.itens) : setOptionsTributacaoCfe([]);
                })
                .catch(err => {
                    setTributacaoCfeError("Não foi possível completar sua busca, tente novamente mais tarde!");
                })

                setLoadingTributacaoCfe(false);
            };
        })();
    }, [searchTributacaoCfe]);

    //Autocomplete para busca na API a Tributação para NF sem Redução
    const [tributacaoNfSemReducao, setTributacaoNfSemReducao] = React.useState('')
    const [tributacaoNfSemReducaoError, setTributacaoNfSemReducaoError] = React.useState(false);
    const [searchTributacaoNfSemReducao, setSearchTributacaoNfSemReducao] = React.useState('');
    const [optionsTributacaoNfSemReducao, setOptionsTributacaoNfSemReducao] = React.useState([]);
    const [openTributacaoNfSemReducao, setOpenTributacaoNfSemReducao] = React.useState(false);
    const [loadingTributacaoNfSemReducao, setLoadingTributacaoNfSemReducao] = React.useState(false);

    //Pesquisar Tributação para NF sem Redução
    useEffect(() => {
        (async() => {
            if(searchTributacaoNfSemReducao !== '' && searchTributacaoNfSemReducao !== tributacaoNfSemReducao.titulo){
                setLoadingTributacaoNfSemReducao(true);

                const params = {
                    "tipo": "aliquotaicms",
                    "palavraChave": searchTributacaoNfSemReducao,
                    "buscaExataPorCodigo": false
                }

                await Getway.search(params, getway.token)
                .then(response => {
                    (response.data.itens.length > 0) ? setOptionsTributacaoNfSemReducao(response.data.itens) : setOptionsTributacaoNfSemReducao([]);
                })
                .catch(err => {
                    setTributacaoNfSemReducaoError("Não foi possível completar sua busca, tente novamente mais tarde!");
                })

                setLoadingTributacaoNfSemReducao(false);
            };
        })();
    }, [searchTributacaoNfSemReducao]);

    //Redução Saída para NF
    const [reducao, setReducao] = useState('');
    const [reducaoError, setReducaoError] = useState(false);
    const handleChangeReducao = (value) => (value.length <= 3 && Number(value) <= 100) && setReducao(value.replace(/\D/g, ''));

    //Alíquota IBPT
    const [ibpt, setIbpt] = useState('');
    const [ibptError, setIbptError] = useState(false);
    const handleChangeIbpt = (value) => (value.length <= 3 && Number(value) <= 100) && setIbpt(value.replace(/\D/g, ''));

    //CodigoBeneficio
    const [codigoBeneficio, setCodigoBeneficio] = useState('');
    const [codigoBeneficioError, setCodigoBeneficioError] = useState(false);
    const handleChangeCodigoBeneficio = (value) => {
        if(value.length <= 8) {
            if(!isNaN(Number(value[0]))) return setCodigoBeneficio(`RJ${value}`);

            if(isNaN(Number(value[0])) && value.length <= 2) return setCodigoBeneficio(value.replace(/[^a-zA-Z]/g, '').slice(0, 2).toUpperCase());

            if(value.replace(/[^a-zA-Z]/g, '').length > 2) return setCodigoBeneficio(`${value.replace(/[^a-zA-Z]/g, '').slice(0, 2).toUpperCase()}${value.replace(/[^0-9]/g, '')}`);

            setCodigoBeneficio(value.replace(/[^a-zA-Z0-9]/g, ''));
        };
    };

    //CodigoInformativo
    const [codigoInformativo, setCodigoInformativo] = useState('');
    const [codigoInformativoError, setCodigoInformativoError] = useState(false);
    const handleChangeCodigoInformativo = (value) => {
        if(value.length <= 10) {
            if(!isNaN(Number(value[0]))) return setCodigoInformativo(`RJ${value}`);

            if(isNaN(Number(value[0])) && value.length <= 2) return setCodigoInformativo(value.replace(/[^a-zA-Z]/g, '').slice(0, 2).toUpperCase());

            if(value.replace(/[^a-zA-Z]/g, '').length > 2) return setCodigoInformativo(`${value.replace(/[^a-zA-Z]/g, '').slice(0, 2).toUpperCase()}${value.replace(/[^0-9]/g, '')}`);

            setCodigoInformativo(value.replace(/[^a-zA-Z0-9]/g, ''));
        };
    };

    //AliqIcmsDesonerado
    const [aliqIcmsDesonerado, setAliqIcmsDesonerado] = useState('');
    const [aliqIcmsDesoneradoError, setAliqIcmsDesoneradoError] = useState(false);
    const handleChangeAliqIcmsDesonerado = (value) => {
        const aliquota = value.replace(/[^0-9]/g, '');
        if(aliquota <= 100) setAliqIcmsDesonerado(aliquota)
    };
    
    //NCM
    const [ncm, setNcm] = useState('');
    const [ncmError, setNcmError] = useState(false);
    const handleChangeNcm = (value) => {
        if(value.length <= 10) {
            let ncm = value.replace(/\D/g, '');
            ncm = ncm.replace(/^(\d{4})(\d)/, '$1.$2'); // adiciona ponto após os dois primeiros dígitos
            ncm = ncm.replace(/^(\d{4})\.(\d{2})(\d)/, '$1.$2.$3'); // adiciona ponto após os próximos três dígitos
            setNcm(ncm);
        };
    };

    //CEST
    const [cest, setCest] = useState('');
    const [cestError, setCestError] = useState(false);
    const handleChangeCest = (value) => {
        if(value.length <= 9) {
            let cest = value.replace(/\D/g, '');
            cest = cest.replace(/^(\d{2})(\d)/, '$1.$2'); // adiciona ponto após os dois primeiros dígitos
            cest = cest.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3'); // adiciona ponto após os próximos três dígitos
            setCest(cest);
        };
    };

    //Natureza
    const [natureza, setNatureza] = useState('');
    const [naturezaError, setNaturezaError] = useState(false);
    const handleChangeNatureza = (value) => {
        if(value.length <= 3) {
            let cest = value.replace(/\D/g, '');
            setNatureza(cest);
        };
    };

    //Autocomplete para busca na API o CST PIS
    const [cstPisSaida, setCstPisSaida] = React.useState('')
    const [cstPisSaidaError, setCstPisSaidaError] = React.useState(false);
    const [searchCstPisSaida, setSearchPisSaida] = React.useState('');
    const [optionsCstPisSaida, setOptionsCstPisSaida] = React.useState([]);
    const [openCstPisSaida, setOpenCstPisSaida] = React.useState(false);
    const [loadingCstPisSaida, setLoadingCstPisSaida] = React.useState(false);

    const [cstOld, setCstOld] = useState('');
    const [tributacaoCfeOld, setTributacaoCfeOld] = useState('');
    const [tributacaoSemNfOld, setTributacaoSemNfOld] = useState('');

    const [reducaoOld, setReducaoOld] = useState('');
    const [aliqIbptOld, setAliqIbptOld] = useState('');

    const [codigoBeneficioOld, setCodigoBeneficioOld] = useState('');
    const [codigoInformativoOld, setCodigoInformativoOld] = useState('');
    const [aliqIcmsDesoneradoOld, setAliqIcmsDesoneradoOld] = useState('');

    const [cstPisSaidaOld, setCstPisSaidaOld] = useState('');

    //Pesquisar CST ICMS de Saída
    useEffect(() => {
        (async() => {
            if(searchCstPisSaida !== '' && searchCstPisSaida !== cstPisSaida.titulo){
                setLoadingCstPisSaida(true);

                const params = {
                    "tipo": "cstcofinsaida",
                    "palavraChave": searchCstPisSaida,
                    "buscaExataPorCodigo": false
                }

                await Getway.search(params, getway.token)
                .then(response => {
                    (response.data.itens.length > 0) ? setOptionsCstPisSaida(response.data.itens) : setOptionsCstPisSaida([]);
                })
                .catch(err => {
                    setCstPisSaidaError("Não foi possível completar sua busca, tente novamente mais tarde!");
                })

                setLoadingCstPisSaida(false);
            };
        })();
    }, [searchCstPisSaida]);

    //Carregar tributações salvas anteriormente no banco de dados
    useEffect(() => {
        (async() => {
            try {
                if(Boolean(Object.keys(product).length)) {
                    setLoadingImposto(true);

                    //Consultar tributações na Getway
                    const paramsTributacaoGetway = {
                        "paginacao": {
                            "paginaAtual": 0,
                            "itensPorPagina": 500,
                            "colunaOrdenacao": "id",
                            "direcaoOrdenacao": ""
                        },
                        "id": null,
                        "descricao": null
                    };
                    const responseTributacaoGetway = (await Getway.searchTributacao(paramsTributacaoGetway, getway.token)).data;

                    //Consultar alíquotas na Getway
                    const paramsAliqIcmsGetway = {
                        "paginacao": {
                            "paginaAtual": 0,
                            "itensPorPagina": 50,
                            "colunaOrdenacao": "id",
                            "direcaoOrdenacao": ""
                        },
                        "id": null,
                        "descricao": null
                    };
                    const responseAliqIcmsGetway = (await Getway.searchAliqIcms(paramsAliqIcmsGetway, getway.token)).data;

                    const paramsProduct = show ? { estadual: product?.impostosEstaduais, federal: product?.impostosFederais } : product?.fgf[0].new[0];
                    if(paramsProduct?.estadual) {
                        console.log('Test', product?.fgf[0].new)
                        //Inserir CST ICMS Saída
                        const cst = responseTributacaoGetway.itens.filter(x => x.id.replace(' ', '') === paramsProduct.estadual[0].codCstSaida.replace(' ', ''));
                        setCstIcmsSaida({id: cst[0].id, titulo: `${cst[0].id.replace(' ', '')} - ${cst[0].descricao}`, descricao: null, unidade: '1'});

                        //Inserir Tributação CFE e Trib. NF Sem Redução
                        const aliqCfe = [responseAliqIcmsGetway.itens.filter(x => x.impressaoId.toUpperCase() === paramsProduct.estadual[0].tribEcf.replaceAll(' ', ''))[0]];
                        setTributacaoCfe({id: aliqCfe[0].id, titulo: `${aliqCfe[0].id} - ${aliqCfe[0].descricao} `, descricao: null, unidade: null});
                        
                        const aliqNfSemReducao = [responseAliqIcmsGetway.itens.filter(x => x.impressaoId.toUpperCase() === paramsProduct.estadual[0].tribNfSemRed.replaceAll(' ', ''))[0]];
                        setTributacaoNfSemReducao({id: aliqNfSemReducao[0].id, titulo: `${aliqNfSemReducao[0].id} - ${aliqNfSemReducao[0].descricao} `, descricao: null, unidade: null});
    
                        setReducao(paramsProduct.estadual[0].redSaidaNf === 0 ? '' : paramsProduct.estadual[0].redSaidaNf);
                        setIbpt(paramsProduct.estadual[0].aliqIbpt === 0 ? '' : paramsProduct.estadual[0].aliqIbpt);
        
                        setCodigoBeneficio(paramsProduct.estadual[0].codDes || '');
                        setCodigoInformativo(paramsProduct.estadual[0].codBenInc || '');
                        setAliqIcmsDesonerado(paramsProduct.estadual[0].aliqBen || '');

                        //Inserir NCM
                        setNcm(paramsProduct.federal.codigoNCM.replace(/^(\d{4})(\d{2})(\d{2})$/, '$1.$2.$3'));
        
                        //Inserir CEST
                        if(paramsProduct.federal.codigoCEST !== null && paramsProduct.federal.codigoCEST !== '') setCest(paramsProduct.federal.codigoCEST.replace(/^(\d{2})(\d{3})(\d{2})$/, '$1.$2.$3'));
        
                        //Inserir Código de Natureza
                        if(paramsProduct.federal.naturezaReceita !== null && paramsProduct.federal.naturezaReceita !== '') setNatureza(paramsProduct.federal.naturezaReceita);
                        
                        //Inserir PIS/COFINS
                        const paramsPisCofins = {
                            "tipo": "cstcofinsaida",
                            "palavraChave": String(paramsProduct.federal.cstPisCofinsSaida.length === 1 ? `0${paramsProduct.federal.cstPisCofinsSaida}` : paramsProduct.federal.cstPisCofinsSaida),
                            "buscaExataPorCodigo": true
                        };
                        const responsePisCofins = (await Getway.search(paramsPisCofins, getway.token)).data;
                        setCstPisSaida(responsePisCofins.itens[0]);
                    };

                    const cstOldDescription = responseTributacaoGetway.itens.filter(x => x.id.replace(' ', '') === product.impostosEstaduais[0].codCstSaida.replace(' ', ''));
                    setCstOld({id: cstOldDescription[0].id, titulo: `${cstOldDescription[0].id.replace(' ', '')} - ${cstOldDescription[0].descricao}`, descricao: null, unidade: '1'});

                    const aliqIcmsOldEcf = responseAliqIcmsGetway.itens.filter(x => x.impressaoId.toUpperCase() === product.impostosEstaduais[0].tribEcf.replaceAll(' ', ''))[0];
                    setTributacaoCfeOld({id: aliqIcmsOldEcf.id, titulo: `${aliqIcmsOldEcf.id} - ${aliqIcmsOldEcf.descricao} `, descricao: null, unidade: null});

                    const aliqImcsOldSemNf = responseAliqIcmsGetway.itens.filter(x => x.impressaoId.toUpperCase() === product.impostosEstaduais[0].tribNfSemRed.replaceAll(' ', ''))[0];
                    setTributacaoSemNfOld({id: aliqImcsOldSemNf.id, titulo: `${aliqImcsOldSemNf.id} - ${aliqImcsOldSemNf.descricao} `, descricao: null, unidade: null});
    
                    setReducaoOld(product.impostosEstaduais[0].redSaidaNf === 0 ? '' : product.impostosEstaduais[0].redSaidaNf);
                    setAliqIbptOld(product.impostosEstaduais[0].aliqIbpt === 0 ? '' : product.impostosEstaduais[0].aliqIbpt);

                    setCodigoBeneficioOld(product.impostosEstaduais[0].codDes || '');
                    setCodigoInformativoOld(product.impostosEstaduais[0].codBenInc || '');
                    setAliqIcmsDesoneradoOld(product.impostosEstaduais[0].aliqBen || '');

                    const paramsPisCofinsAntigo = {
                        "tipo": "cstcofinsaida",
                        "palavraChave": String(product.impostosFederais.cstPisCofinsSaida.length === 1 ? `0${product.impostosFederais.cstPisCofinsSaida}` : product.impostosFederais.cstPisCofinsSaida),
                        "buscaExataPorCodigo": true
                    };
                    const responsePisCofinsAntigo = (await Getway.search(paramsPisCofinsAntigo, getway.token)).data;
                    setCstPisSaidaOld(responsePisCofinsAntigo.itens[0]);
                };
            } catch(err) {
                console.log('Carregando imposto do servidor:', err.debugger || err.message);

                Swal.fire({
                    title: `<label style="font-size:18px;font-family:Muli">${'Atenção'}</label>`,
                    html: `<label style="font-size:14px;font-family:Muli">Falha em carregar as tributações cadastradas no servidor, caso persista entre em contato com suporte do aplicativo</label>`,
                    icon: 'error',
                    width: '30em',
                    confirmButtonColor: theme.palette.primary.main,
                    confirmButtonText: `<label style="font-size:14px;font-family:Muli">CONCLUIR</label>`
                });
            } finally {
                setLoadingImposto(false);
            };
        })();
    }, [product]);

    const handleImport = async() => {
        try {
            const data = await FGF.validar(JSON.parse(fgf));
            setLoadingImposto(true);

            setCstIcmsSaida('');
            setTributacaoCfe('');
            setTributacaoNfSemReducao('');
            setReducao('');
            setIbpt('');
            setCodigoBeneficio('');
            setCodigoInformativo('');
            setAliqIcmsDesonerado('');
            setNcm('');
            setCest('');
            setNatureza('');
            setCstPisSaida('');

            setCstIcmsSaidaError(false);
            setTributacaoCfeError(false);
            setTributacaoNfSemReducaoError(false);
            setReducaoError(false);
            setIbptError(false);
            setCodigoBeneficioError(false);
            setCodigoInformativoError(false);
            setAliqIcmsDesoneradoError(false);
            setNcmError(false);
            setCestError(false);
            setNaturezaError(false);
            setCstPisSaidaError(false);
            
            //Consulta tributações na Getway
            const paramsTributacao = {
                "paginacao": {
                    "paginaAtual": 0,
                    "itensPorPagina": 500,
                    "colunaOrdenacao": "id",
                    "direcaoOrdenacao": ""
                },
                "id": null,
                "descricao": null
            };
            const responseTributacao = (await Getway.searchTributacao(paramsTributacao, getway.token)).data;

            //Consulta Alíquota de ICMS na Getway
            const paramsAliqIcms = {
                "paginacao": {
                    "paginaAtual": 0,
                    "itensPorPagina": 50,
                    "colunaOrdenacao": "id",
                    "direcaoOrdenacao": ""
                },
                "id": null,
                "descricao": null
            };
            const responseAliqIcms = (await Getway.searchAliqIcms(paramsAliqIcms, getway.token)).data;
            
            //Verificar se existe o CST ICMS cadastrada
            const cst = responseTributacao.itens.filter(x => x.id.replace(' ', '') === data.cst);
            if(!Boolean(cst.length)) throw { message: `Não foi possível localizar em Cadastro > Produtos > Tributação > Tributações o CST ICMS: <b>${data.cst}%</b>, verifique os cadastros das tributações, caso persista entre em contato com suporte do aplicativo` };
            setCstIcmsSaida({id: cst[0].id, titulo: `${cst[0].id.replace(' ', '')} - ${cst[0].descricao}`, descricao: null, unidade: '1'});

            //Importar código de benefício, código informativo e alíquota icms desonerado
            if(cstComBeneficio.includes(cst[0].id.replace(' ', ''))) {
                setCodigoBeneficio(data.codbenef === null ? '' : data.codbenef);
                setCodigoInformativo(data.codinfo === null ? '' : data.codinfo);
                setAliqIcmsDesonerado(data.aliquotaDesoneracao === 0 ? '' : data.aliquotaDesoneracao);
            };

            //Importar Tributação CFE e Trib. NF Sem Redução
            if(cst[0].id.replace(' ', '') === '060') {
                setTributacaoCfe({ id: 'F ', titulo: 'F - SUBST TRIBUTARIA', descricao: null, unidade: null });
                setTributacaoNfSemReducao({ id: 'F ', titulo: 'F - SUBST TRIBUTARIA', descricao: null, unidade: null });
            };

            if(cst[0].id.replace(' ', '') === '040') {
                setTributacaoCfe({ id: 'I ', titulo: 'I - ISENTO', descricao: null, unidade: null });
                setTributacaoNfSemReducao({ id: 'I ', titulo: 'I - ISENTO', descricao: null, unidade: null });
            };

            if(cst[0].id.replace(' ', '') !== '040' && cst[0].id.replace(' ', '') !== '060') {
                const aliqEcf = [responseAliqIcms.itens.filter(x => x.valorTributacao === data.aliquota)[0]];
                if(!Boolean(aliqEcf.length)) throw { message: `Não foi possível localizar em Cadastro > Produtos > Tributação > Alíquotas Imp. Fiscal a Alíquota: <b>${data.aliquota}%</b>, verifique os cadastros das alíquotas, caso persista entre em contato com suporte do aplicativo` };
                setTributacaoCfe({id: aliqEcf[0].id, titulo: `${aliqEcf[0].id} - ${aliqEcf[0].descricao} `, descricao: null, unidade: null});
                setTributacaoNfSemReducao({id: aliqEcf[0].id, titulo: `${aliqEcf[0].id} - ${aliqEcf[0].descricao} `, descricao: null, unidade: null});
            };

            // Importar NCM
            if(data.ncm !== null && data.ncm !== '') setNcm(data.ncm.replace(/^(\d{4})(\d{2})(\d{2})$/, '$1.$2.$3'));

            //Importar CEST
            if(data.cest !== null && data.cest !== '') setCest(data.cest.replace(/^(\d{2})(\d{3})(\d{2})$/, '$1.$2.$3'));

            //Importar Código de Natureza
            if(data.natureza !== null && data.natureza !== '') setNatureza(data.natureza);

            //Importar PIS/COFINS
            const paramsPisCofins = {
                "tipo": "cstcofinsaida",
                "palavraChave": String(data.pis.length === 1 ? `0${data.pis}` : data.pis),
                "buscaExataPorCodigo": true
            };
            const responsePisCofins = (await Getway.search(paramsPisCofins, getway.token)).data;
            if(!Boolean(responsePisCofins?.itens.length)) throw { message: `Não foi possível localizar o CST de PIS/COFINS, tente novamente e caso persista entre em contato com suporte do aplicativo` };
            setCstPisSaida(responsePisCofins.itens[0]);
        } catch(err) {
            console.log('Importar informações da FGF:', err.debugger || err.message);

            Swal.fire({
                title: `<label style="font-size:18px;font-family:Muli">${'Atenção'}</label>`,
                html: `<label style="font-size:14px;font-family:Muli">${err.message.includes('JSON') ? 'Formato inválido, acesse novamente a FGF e clica no botão de copiar dados, após isso tente novamente, caso persista entre em contato com suporte do aplicativo' : err.message}</label>`,
                icon: 'error',
                width: '30em',
                confirmButtonColor: theme.palette.primary.main,
                confirmButtonText: `<label style="font-size:14px;font-family:Muli">CONCLUIR</label>`
            });
        } finally {
            setLoadingImposto(false);
        };
    };

    const handleOnBlur = (event) => {
        if(event.target.name === 'cstIcmsSaida') {
            if(searchCstIcmsSaida === '*') setSearchIcmsSaida('')
            if(event.target.value === '') return setCstIcmsSaidaError(true);
        };

        if(event.target.name === 'codigoBeneficio') {
            if(event.target.value.length < 8 && event.target.value !== '') return setCodigoBeneficioError(true);
            if(cstComBeneficio.includes(cstIcmsSaida.id.replace(' ', '')) && event.target.value === '') return setCodigoBeneficioError(true);
        };

        if(event.target.name === 'codigoInformativo') {
            if(event.target.value.length < 10 && event.target.value !== '') return setCodigoInformativoError(true);
            if(cstComBeneficio.includes(cstIcmsSaida.id.replace(' ', '')) && event.target.value === '') return setCodigoInformativoError(true);
        };

        if(event.target.name === 'aliqIcmsDesonerado') {
            if(cstIcmsSaida !== '' && event.target.value === '' && cstComBeneficio.includes(cstIcmsSaida.id.replace(' ', ''))) return setAliqIcmsDesoneradoError(true);
        };

        if(event.target.name === 'ncm') {
            if((event.target.value === '' && cstIcmsSaida !== '') || (event.target.value.length < 10 && event.target.value !== '')) return setNcmError(true);
        };

        if(event.target.name === 'cest') {
            if((cstIcmsSaida !== '' && event.target.value === '' && cstComCest.includes(cstIcmsSaida.id.replace(' ', ''))) || ( event.target.value !== '' && event.target.value.length < 9)) return setCestError(true);
        };

        if(event.target.name === 'natureza') {
            if((cstPisSaida !== '' && cstComNatureza.includes(cstPisSaida.id) && event.target.value === '')) return setNaturezaError(true);
        };

        if(event.target.name === 'cstPisSaida') {
            if(searchCstPisSaida === '*') setSearchPisSaida('')
            if(event.target.value === '' && cstIcmsSaida !== '') return setCstPisSaidaError(true);
        };
    };

    const handleOnFocus = (event) => {
        if(event.target.name === 'cstIcmsSaida') setCstIcmsSaidaError(false);
        if(event.target.name === 'codigoBeneficio') setCodigoBeneficioError(false);
        if(event.target.name === 'codigoInformativo') setCodigoInformativoError(false);
        if(event.target.name === 'aliqIcmsDesonerado') setAliqIcmsDesoneradoError(false);
        if(event.target.name === 'ncm') setNcmError(false);
        if(event.target.name === 'cest') setCestError(false);
        if(event.target.name === 'natureza') setNaturezaError(false);
        if(event.target.name === 'cstPisSaida') setCstPisSaidaError(false);
    };
    
    const errors = [
        {
            error: cstIcmsSaidaError,
            name: `CST ICMS Saida`,
            msg: `Não preenchido ou inválido, verificar o campo se está selecionado corretamente`,
            example: ''
        },
        {
            error: tributacaoCfeError,
            name: `Tributação ECF`,
            msg: `Não preenchido ou inválido, verificar o campo se está selecionado corretamente`,
            example: ''
        },
        {
            error: tributacaoNfSemReducaoError,
            name: `Trib. NF Sem Redução`,
            msg: `Não preenchido ou inválido, verificar o campo se está selecionado corretamente`,
            example: ''
        },
        {
            error: codigoBeneficioError,
            name: `Código de Benefício`,
            msg: `Não preenchido ou inválido, para as CST ICMS Saída: ${cstComBeneficio.slice(0, cstComBeneficio.length-1).join(', ')} e ${cstComBeneficio[cstComBeneficio.length-1]} é obrigatório e contém 8 caracteres`,
            example: 'Exemplo: RJ801122'
        },
        {
            error: codigoInformativoError,
            name: 'Código Informativo',
            msg: `Não preenchido ou inválido, para as CST ICMS Saída: ${cstComBeneficio.slice(0, cstComBeneficio.length-1).join(', ')} e ${cstComBeneficio[cstComBeneficio.length-1]} é obrigatório e contém 10 caracteres`,
            example: 'Exemplo: RJ90980000'
        },
        {
            error: aliqIcmsDesoneradoError,
            name: 'Alíquota de ICMS Desonerado',
            msg: `Não preenchido ou inválido, para as CST ICMS Saída: ${cstComBeneficio.slice(0, cstComBeneficio.length-1).join(', ')} e ${cstComBeneficio[cstComBeneficio.length-1]} é obrigatório e pode variar de 0 a 100`,
            example: ''
        },
        {
            error: ncmError,
            name: 'NCM',
            msg: `Não preenchido ou inválido, o NCM é obrigatório e contém 8 dígitos`,
            example: 'Exemplo: 0000.00.00'
        },
        {
            error: cestError,
            name: 'CEST',
            msg: `para as CST ICMS Saída: ${cstComCest.slice(0, cstComCest.length-1).join(', ')} e ${cstComCest[cstComCest.length-1]} é obrigatório e contém 7 dígitos`,
            example: 'Exemplo: 00.000.00'
        },
        {
            error: naturezaError,
            name: 'Natureza de Receita',
            msg: `Não preenchido ou inválido, para as CST PIS/COFINS Saída: ${cstComNatureza.slice(0, cstComNatureza.length-1).join(', ')} e ${cstComNatureza[cstComNatureza.length-1]} é obrigatório e pode variar de 0 a 999`,
            example: ''
        },
        {
            error: cstPisSaidaError,
            name: `CST PIS/COFINS Saída`,
            msg: `Não preenchido ou inválido, verificar o campo se está selecionado corretamente`,
            example: ''
        },
    ];

    const [modalError, setModalError] = useState(false);
    const handleModalError = () => setModalError(true);

    const handleConfirmSave = async() => {
        if(cstIcmsSaida === '') return setCstIcmsSaidaError(true);
        if(tributacaoCfe === '') return setTributacaoCfeError(true);
        if(tributacaoNfSemReducao === '') return setTributacaoNfSemReducaoError(true);
        if((codigoBeneficio === '' || codigoBeneficio === null) && cstComBeneficio.includes(cstIcmsSaida.id.replace(' ', ''))) return setCodigoBeneficioError(true);
        if(codigoBeneficio.length < 8 && cstComBeneficio.includes(cstIcmsSaida.id.replace(' ', ''))) return setCodigoBeneficioError(true);
        if((codigoInformativo === '' || codigoInformativo === null) && cstComBeneficio.includes(cstIcmsSaida.id.replace(' ', ''))) return setCodigoInformativoError(true);
        if(codigoInformativo.length < 10 && cstComBeneficio.includes(cstIcmsSaida.id.replace(' ', ''))) return setCodigoInformativoError(true);

        if((aliqIcmsDesonerado === '' || aliqIcmsDesonerado <= 0) && cstComBeneficio.includes(cstIcmsSaida.id.replace(' ', ''))) return setAliqIcmsDesoneradoError(true);

        if((ncm === '' && cstIcmsSaida !== '') || (ncm.length < 10 && ncm !== '')) return setNcmError(true);
        if(((cstIcmsSaida !== '' && cest === '' && cstComCest.includes(cstIcmsSaida.id.replace(' ', ''))) || (cest !== '' && cest.length < 9))) return setCestError(true);
        if((cstPisSaida !== '' && cstComNatureza.includes(cstPisSaida.id) && natureza === '')) return setNaturezaError(true);
        if(cstPisSaida === '') return setCstPisSaidaError(true);

        Swal.fire({
            title: `<label style="font-size:18px;font-family:Muli">${'Atenção'}</label>`,
            html: `
                <div style="font-size:14px;font-family:Muli">
                    <label style="font-size:14px;font-family:Muli">Antes de confirmar as alterações dos impostos dos produtos, tenha certeza que a tributação atual do produto esteja de acordo com definitivo, caso <b>não</b> esteja corretamente, efetue a correção no Samb@net e depois venha realize a tarefa</label>
                    
                    <div style="text-align:left;margin-top:10px;display:flex;gap:8px;flex-direction:column">
                        <div style="display:flex;flex-direction:column;gap:2px">
                            <div style="font-size:10px">
                                CST ICMS Saída
                            </div>
                            <div style="font-size:12px">
                                ${cstOld.titulo || ''}
                            </div>
                        </div>

                        <div style="display:flex;flex-direction:row;gap:10px;justify-content:space-between">
                            <div style="display:flex;flex-direction:column;gap:2px">
                                <div style="font-size:10px">
                                    Tributação CFE
                                </div>
                                <div style="font-size:12px">
                                    ${tributacaoCfeOld.titulo || 'Error'} 
                                </div>
                            </div>
                            <div style="display:flex;flex-direction:column;gap:2px;text-align:right">
                                <div style="font-size:10px">
                                    Trib. NF Sem Redução
                                </div>
                                <div style="font-size:12px">
                                    ${tributacaoSemNfOld.titulo || 'Error'}
                                </div>
                            </div>
                        </div>

                        <div style="display:flex;flex-direction:row;gap:10px;justify-content:space-between">
                            <div style="display:flex;flex-direction:column;gap:2px">
                                <div style="font-size:10px">
                                    Redução Saída NF(%)
                                </div>
                                <div style="font-size:12px">
                                    ${reducaoOld || ''}
                                </div>
                            </div>
                            <div style="display:flex;flex-direction:column;gap:2px;text-align:right">
                                <div style="font-size:10px">
                                    Alíquota IBPT (%)
                                </div>
                                <div style="font-size:12px">
                                    ${aliqIbptOld || ''}
                                </div>
                            </div>
                        </div>

                        <div style="display:flex;flex-direction:row;gap:10px;justify-content:space-between">
                            <div style="display:flex;flex-direction:column;gap:2px">
                                <div style="font-size:10px">
                                    Código Benefício
                                </div>
                                <div style="font-size:12px">
                                    ${codigoBeneficioOld || ''}
                                </div>
                            </div>
                            <div style="display:flex;flex-direction:column;gap:2px">
                                <div style="font-size:10px">
                                    Código Informativo
                                </div>
                                <div style="font-size:12px">
                                    ${codigoInformativoOld || ''}
                                </div>
                            </div>
                            <div style="display:flex;flex-direction:column;gap:2px;text-align:right">
                                <div style="font-size:10px">
                                    Alíq. ICMS Des (%)
                                </div>
                                <div style="font-size:12px">
                                    ${aliqIcmsDesoneradoOld || ''}
                                </div>
                            </div>
                        </div>

                        <div style="display:flex;flex-direction:row;gap:10px;justify-content:space-between">
                            <div style="display:flex;flex-direction:column;gap:2px">
                                <div style="font-size:10px">
                                    NCM
                                </div>
                                <div style="font-size:12px">
                                    ${(product.impostosFederais.codigoNCM === null || product.impostosFederais.codigoNCM === '' || product.impostosFederais.codigoNCM === undefined) ? '' : product.impostosFederais.codigoNCM.includes('.') ? product.impostosFederais.codigoNCM : product.impostosFederais.codigoNCM.replace(/^(\d{4})(\d{2})(\d{2})$/, '$1.$2.$3')}
                                </div>
                            </div>
                            <div style="display:flex;flex-direction:column;gap:2px">
                                <div style="font-size:10px">
                                    CEST
                                </div>
                                <div style="font-size:12px">
                                    ${(product.impostosFederais.codigoCEST === null || product.impostosFederais.codigoCEST === '' || product.impostosFederais.codigoCEST === undefined) ? '' : product.impostosFederais.codigoCEST.includes('.') ? product.impostosFederais.codigoCEST : product.impostosFederais.codigoCEST.replace(/^(\d{2})(\d{3})(\d{2})$/, '$1.$2.$3')}
                                </div>
                            </div>
                            <div style="display:flex;flex-direction:column;gap:2px;text-align:right">
                                <div style="font-size:10px">
                                    Natureza Receita
                                </div>
                                <div style="font-size:12px">
                                    ${product.impostosFederais.naturezaReceita || ''}
                                </div>
                            </div>
                        </div>

                        <div style="display:flex;flex-direction:column;gap:2px">
                            <div style="font-size:10px">
                                CST PIS/COFINS Saída
                            </div>
                            <div style="font-size:12px">
                                ${cstPisSaidaOld.titulo}
                            </div>
                        </div>
                    </div>
                </div>
            `,
            icon: 'warning',
            width: '30em',
            allowOutsideClick: false,
            showCancelButton: true,
            cancelButtonText: `<label style="font-size:14px;font-family:Muli">CANCELAR</label>`,
            cancelButtonColor: theme.custom.color.cancel,
            confirmButtonColor: theme.palette.primary.main,
            confirmButtonText: `<label style="font-size:14px;font-family:Muli">CONFIRMAR</label>`,
            preConfirm: async() => {
                return await handleSave();
            },
        });
    };

    const handleSave = async() => {
        try {
            const novaTributacao = { ...product };

            await Server.setProduct({
                cnpj: getwayStore.cnpj,
                pedidoId: order?.capa.pedidoId,
                prodId: product?.id,
                barra: product?.barraPrincipal,
                old: JSON.stringify({ estadual: product?.impostosEstaduais, federal: product?.impostosFederais })
            });

            novaTributacao.impostosEstaduais[0].codCstSaida = String(cstIcmsSaida.id);

            novaTributacao.impostosEstaduais[0].tribEcf = String(tributacaoCfe.id);
            const paramsAliqTributacaoCfe = {
                "paginacao": {
                    "paginaAtual": 0,
                    "itensPorPagina": 10,
                    "colunaOrdenacao": "id",
                    "direcaoOrdenacao": ""
                },
                "id": String(tributacaoCfe.id),
                "descricao": null
            };
            const aliqTributacaoCfe = (await Getway.searchAliqIcms(paramsAliqTributacaoCfe, getway.token)).data;
            novaTributacao.impostosEstaduais[0].valorTrib = aliqTributacaoCfe.itens[0].valorTributacao;
            novaTributacao.impostosEstaduais[0].tribNfSemRed = String(tributacaoNfSemReducao.id);
        
            novaTributacao.impostosEstaduais[0].redSaidaNf = reducao === '' ? 0 : Number(reducao);
            novaTributacao.impostosEstaduais[0].aliqIbpt = ibpt === '' ? 0 : Number(ibpt);

            novaTributacao.impostosEstaduais[0].codDes = codigoBeneficio === '' ? null : String(codigoBeneficio);
            novaTributacao.impostosEstaduais[0].aliqBen = aliqIcmsDesonerado === '' ? 0 : Number(aliqIcmsDesonerado);
            novaTributacao.impostosEstaduais[0].codBenInc = codigoInformativo === '' ? null : String(codigoInformativo);

            novaTributacao.impostosEstaduais[0].itemSped = '00';

            novaTributacao.impostosFederais.codigoNCM = String(ncm);
            novaTributacao.impostosFederais.codigoCEST = cest === '' ? null : String(cest);
            novaTributacao.impostosFederais.naturezaReceita = natureza === '' ? null : String(natureza);
            novaTributacao.impostosFederais.cstPisCofinsSaida = String(cstPisSaida.id);

            await Server.updateProduct({ 
                cnpj: getwayStore.cnpj,
                pedidoId: order?.capa.pedidoId,
                prodId: product?.id,
                barra: product?.barraPrincipal,
                new: JSON.stringify({ estadual: product?.impostosEstaduais, federal: product?.impostosFederais })
            });

            await handleModalOrderOpen(order?.capa.pedidoId);
            
            handleClose();
        } catch(err) {
            console.log('Salvar tributação:', err.debugger || err.message);

            Swal.fire({
                title: `<label style="font-size:18px;font-family:Muli">${'Atenção'}</label>`,
                html: `<label style="font-size:14px;font-family:Muli">${'Falha em salvar tributação, caso persista entre em contato com suporte do aplicativo'}</label>`,
                icon: 'error',
                width: '30em',
                confirmButtonColor: theme.palette.primary.main,
                confirmButtonText: `<label style="font-size:14px;font-family:Muli">CONCLUIR</label>`
            });
        };
    };

    return (
        <React.Fragment>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={open}
            >
                {!show && (
                    <DialogTitle>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Box>
                                <Typography>Pedido:</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                <TextField
                                    autoComplete="off"
                                    fullWidth
                                    size="small"
                                    focused 
                                    value={fgf}
                                    onChange={(e) => handleChangeFgf(e.target.value)}
                                    onFocus={handleOnFocus}
                                    onBlur={handleOnBlur}
                                    error={fgfError}
                                    name="fgf"
                                    label="Colar da FGF"
                                    variant="outlined"
                                    sx={{ paddingRight: 0 }}
                                    inputProps={{
                                        style: {
                                            fontSize: 11,
                                            padding: '7.5px 8px',
                                            paddingRight: 0
                                        },
                                    }}
                                    InputProps={{
                                        style: {
                                            paddingRight: '2px'
                                        },
                                        endAdornment: (
                                            <IconButton
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection:"row"
                                                }}
                                                disabled={fgf === ''}
                                                onClick={() => setFgf('')}
                                                size={'small'}
                                            >
                                                <CloseIcon sx={{ fontSize: 20, color: fgf === '' ? '#848484' : '#f44336' }}/>
                                            </IconButton>
                                        )
                                    }}
                                />
                                <Button variant='contained' disabled={fgf === ''} sx={{ padding: '0px 18px' }} onClick={handleImport} size="small">IMPORTAR</Button>
                            </Box>
                        </Box>
                    </DialogTitle>
                )}
                <DialogContent>
                    <Box sx={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                        <Box sx={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-between', border: '2px #ffffff0a solid', borderRadius: '4px', padding: 2 }}>
                            <CstICmsSaidaAutocomplete
                                readOnly={Boolean(Object.keys(cstIcmsSaida).length) || show}
                                open={openCstIcmsSaida}
                                value={cstIcmsSaida}
                                onChange={(event, value) => {
                                    if(value !== null) setCstIcmsSaida(value)
                                }}
                                onBlur={handleOnBlur}
                                inputValue={searchCstIcmsSaida}
                                onInputChange={(event, value) => {
                                    if(value === cstIcmsSaida.titulo) setSearchIcmsSaida(value);

                                    if(value !== undefined && value !== ' ' && value !== cstIcmsSaida.titulo) {
                                        setSearchIcmsSaida(value);
                                        setOpenCstIcmsSaida(true);
                                    };

                                    if(value === '') {
                                        setCstIcmsSaida('')
                                        setSearchIcmsSaida('');
                                        setOpenCstIcmsSaida(false);
                                    };
                                }}
                                noOptionsText={"Não foram encontrado resultados"}
                                getOptionLabel={(option) => option.titulo ? option.titulo : ''}
                                isOptionEqualToValue={(option, value) => value.id === undefined || option.id === value.id}
                                options={optionsCstIcmsSaida}
                                loading={loadingCstIcmsSaida}
                                loadingText={"Carregando..."}
                                onClose={() => setOpenCstIcmsSaida(false)}
                                renderInput={(params) =>  {
                                    return (
                                        <TextField
                                            name={"cstIcmsSaida"}
                                            variant="standard"
                                            error={cstIcmsSaidaError}
                                            {...params}
                                            onFocus={handleOnFocus}
                                            margin="dense"
                                            required
                                            size="small"
                                            label="CST ICMS Saída"
                                            placeholder="Digite o nome ou código"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <Box sx={{right:0, position:'absolute', display:'flex'}}>
                                                        {loadingCstIcmsSaida ? <CircularProgress color="inherit" size={16} /> : null}
                                                            <Box sx={{display:'flex'}}>
                                                                {(Boolean(Object.keys(cstIcmsSaida).length) && !show) &&
                                                                    <IconButton
                                                                        sx={{
                                                                            display:'flex',
                                                                            flexDirection:"row"
                                                                        }}
                                                                        onClick={() => {
                                                                            setCstIcmsSaida('');
                                                                            setSearchIcmsSaida('');
                                                                            setCstIcmsSaidaError(false);
                                                                            setCodigoBeneficioError(false);
                                                                            setCodigoInformativoError(false);
                                                                            setAliqIcmsDesoneradoError(false);
                                                                            setCodigoBeneficio('');
                                                                            setCodigoInformativo('');
                                                                            setAliqIcmsDesonerado('');
                                                                            setTributacaoCfe('')
                                                                            setTributacaoCfeError(false);
                                                                            setTributacaoNfSemReducao('');
                                                                            setTributacaoNfSemReducaoError(false);
                                                                        }}
                                                                        size={'small'}
                                                                    >
                                                                        <CloseIcon sx={{ fontSize: 20, color: '#f44336' }}/>
                                                                    </IconButton>
                                                                }
                                                            </Box>
                                                    </Box>
                                                ),
                                            }}
                                        />
                                    )
                                }}
                            /> 

                            <Box sx={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
                                <Box sx={{ width: '80%' }}>
                                    <TributacaoCfe
                                        readOnly={Boolean(Object.keys(tributacaoCfe).length) || show}
                                        open={openTributacaoCfe}
                                        value={tributacaoCfe}
                                        onChange={(event, value) => {
                                            if(value !== null) setTributacaoCfe(value)
                                        }}
                                        onBlur={() => {
                                            if(searchTributacaoCfe === '*') setSearchTributacaoCfe('')
                                        }}
                                        inputValue={searchTributacaoCfe}
                                        onInputChange={(event, value) => {
                                            if(value === tributacaoCfe.titulo) setSearchTributacaoCfe(value);

                                            if(value !== undefined && value !== ' ' && value !== tributacaoCfe.titulo) {
                                                setSearchTributacaoCfe(value);
                                                setOpenTributacaoCfe(true);
                                            };

                                            if(value === '') {
                                                setTributacaoCfe('')
                                                setSearchTributacaoCfe('');
                                                setOpenTributacaoCfe(false);
                                            };
                                        }}
                                        noOptionsText={"Não foram encontrado resultados"}
                                        getOptionLabel={(option) => option.titulo ? option.titulo : ''}
                                        isOptionEqualToValue={(option, value) => value.id === undefined || option.id === value.id}
                                        options={optionsTributacaoCfe}
                                        loading={loadingTributacaoCfe}
                                        loadingText={"Carregando..."}
                                        onClose={() => setOpenTributacaoCfe(false)}
                                        renderInput={(params) =>  {
                                            return (
                                                <TextField
                                                    variant="standard"
                                                    error={tributacaoCfeError}
                                                    {...params}
                                                    onFocus={() => setTributacaoCfeError(false)}
                                                    margin="dense"
                                                    required
                                                    size="small"
                                                    label="Tributação CFE"
                                                    placeholder="Digite o nome ou código"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <Box sx={{right:0, position:'absolute', display:'flex'}}>
                                                                {loadingTributacaoCfe ? <CircularProgress color="inherit" size={16} /> : null}
                                                                    <Box sx={{display:'flex'}}>
                                                                        {(Boolean(Object.keys(tributacaoCfe).length) && !show) &&
                                                                            <IconButton
                                                                                sx={{
                                                                                    display:'flex',
                                                                                    flexDirection:"row"
                                                                                }}
                                                                                onClick={() => {
                                                                                    setTributacaoCfe('');
                                                                                    setSearchTributacaoCfe('');
                                                                                }}
                                                                                size={'small'}
                                                                            >
                                                                                <CloseIcon sx={{ fontSize: 20, color: '#f44336' }}/>
                                                                            </IconButton>
                                                                        }
                                                                    </Box>
                                                            </Box>
                                                        ),
                                                    }}
                                                />
                                            )
                                        }}
                                    />
                                </Box>
                                <Box sx={{ width: '80%' }}>
                                    <TributacaoCfe
                                        readOnly={Boolean(Object.keys(tributacaoNfSemReducao).length) || show}
                                        open={openTributacaoNfSemReducao}
                                        value={tributacaoNfSemReducao}
                                        onChange={(event, value) => {
                                            if(value !== null) setTributacaoNfSemReducao(value)
                                        }}
                                        onBlur={() => {
                                            if(searchTributacaoNfSemReducao === '*') setSearchTributacaoNfSemReducao('')
                                        }}
                                        inputValue={searchTributacaoNfSemReducao}
                                        onInputChange={(event, value) => {
                                            if(value === tributacaoNfSemReducao.titulo) setSearchTributacaoNfSemReducao(value);

                                            if(value !== undefined && value !== ' ' && value !== tributacaoNfSemReducao.titulo) {
                                                setSearchTributacaoNfSemReducao(value);
                                                setOpenTributacaoNfSemReducao(true);
                                            };

                                            if(value === '') {
                                                setTributacaoNfSemReducao('')
                                                setSearchTributacaoNfSemReducao('');
                                                setOpenTributacaoNfSemReducao(false);
                                            };
                                        }}
                                        noOptionsText={"Não foram encontrado resultados"}
                                        getOptionLabel={(option) => option.titulo ? option.titulo : ''}
                                        isOptionEqualToValue={(option, value) => value.id === undefined || option.id === value.id}
                                        options={optionsTributacaoNfSemReducao}
                                        loading={loadingTributacaoNfSemReducao}
                                        loadingText={"Carregando..."}
                                        onClose={() => setOpenTributacaoNfSemReducao(false)}
                                        renderInput={(params) =>  {
                                            return (
                                                <TextField
                                                    variant="standard"
                                                    error={tributacaoNfSemReducaoError}
                                                    {...params}
                                                    onFocus={() => setTributacaoNfSemReducaoError(false)}
                                                    margin="dense"
                                                    required
                                                    size="small"
                                                    label="Trib. NF Sem Redução"
                                                    placeholder="Digite o nome ou código"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <Box sx={{right:0, position:'absolute', display:'flex'}}>
                                                                {loadingTributacaoNfSemReducao ? <CircularProgress color="inherit" size={16} /> : null}
                                                                    <Box sx={{display:'flex'}}>
                                                                        {(Boolean(Object.keys(tributacaoNfSemReducao).length) && !show) &&
                                                                            <IconButton
                                                                                sx={{
                                                                                    display:'flex',
                                                                                    flexDirection:"row"
                                                                                }}
                                                                                onClick={() => {
                                                                                    setTributacaoNfSemReducao('');
                                                                                    setSearchTributacaoNfSemReducao('');
                                                                                }}
                                                                                size={'small'}
                                                                            >
                                                                                <CloseIcon sx={{ fontSize: 20, color: '#f44336' }}/>
                                                                            </IconButton>
                                                                        }
                                                                    </Box>
                                                            </Box>
                                                        ),
                                                    }}
                                                />
                                            )
                                        }}
                                    />
                                </Box>
                            </Box>

                            <Box sx={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', gap: '10px', marginTop: '10px' }}>
                                <Box sx={{ width: '50%' }}>
                                    <TextField fullWidth value={reducao} onChange={(e) => handleChangeReducao(e.target.value)} onFocus={handleOnFocus} onBlur={handleOnBlur} error={reducaoError && cstIcmsSaida !== ''} name="reducao" label="Redução Saída NF (%)" variant="standard" disabled={show}/>
                                </Box>
                                <Box sx={{ width: '50%' }}>
                                    <TextField fullWidth value={ibpt} onChange={(e) => handleChangeIbpt(e.target.value)} onFocus={handleOnFocus} onBlur={handleOnBlur} error={ibptError && cstIcmsSaida !== ''} name="ibpt" label="Alíquota IBPT (%)" variant="standard"  disabled={show}/>
                                </Box>
                            </Box>

                            <Box sx={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', gap: '10px', marginTop: '10px' }}>
                                <Box sx={{ width: '30%' }}>
                                    <TextField disabled={show || !cstComBeneficio.includes(cstIcmsSaida?.id ? cstIcmsSaida?.id.replace(' ', '') : '')} fullWidth value={codigoBeneficio} onChange={(e) => handleChangeCodigoBeneficio(e.target.value)} onFocus={handleOnFocus} onBlur={handleOnBlur} error={codigoBeneficioError && cstIcmsSaida !== ''} name="codigoBeneficio" label="Código Benefício" variant="standard" />
                                </Box>
                                <Box sx={{ width: '40%' }}>
                                    <TextField disabled={show || !cstComBeneficio.includes(cstIcmsSaida?.id ? cstIcmsSaida?.id.replace(' ', '') : '')} fullWidth value={codigoInformativo} onChange={(e) => handleChangeCodigoInformativo(e.target.value)} onFocus={handleOnFocus} onBlur={handleOnBlur} error={codigoInformativoError && cstIcmsSaida !== ''} name="codigoInformativo" label="Código Informativo" variant="standard" />
                                </Box>
                                <Box sx={{ width: '30%' }}>
                                    <TextField disabled={show || !cstComBeneficio.includes(cstIcmsSaida?.id ? cstIcmsSaida?.id.replace(' ', '') : '')} fullWidth value={aliqIcmsDesonerado} onChange={(e) => handleChangeAliqIcmsDesonerado(e.target.value)} onFocus={handleOnFocus} onBlur={handleOnBlur} error={aliqIcmsDesoneradoError && cstIcmsSaida !== ''} name="aliqIcmsDesonerado" label="Alíq. ICMS Des. (%)" variant="standard"/>
                                </Box>
                            </Box>
                        </Box>

                        <Box sx={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-between', border: '2px #ffffff0a solid', borderRadius: '4px', padding: 2 }}>
                            <Box sx={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
                                <Box sx={{ width: '100%' }}>
                                    <TextField disabled={show} fullWidth value={ncm} onChange={(e) => handleChangeNcm(e.target.value)} error={ncmError} onFocus={handleOnFocus} onBlur={handleOnBlur} name="ncm" label="NCM" variant="standard" />
                                </Box>
                                <Box sx={{ width: '100%' }}>
                                    <TextField disabled={show} fullWidth value={cest} onChange={(e) => handleChangeCest(e.target.value)} error={cestError} onFocus={handleOnFocus} onBlur={handleOnBlur} name="cest" label="CEST" variant="standard" />
                                </Box>
                                <Box sx={{ width: '70%' }}>
                                    <TextField disabled={show} fullWidth value={natureza} onChange={(e) => handleChangeNatureza(e.target.value)} error={naturezaError} onFocus={handleOnFocus} onBlur={handleOnBlur} name="natureza" label="Natureza Receita" variant="standard" />
                                </Box>
                            </Box>

                            <Box>
                                <CstPisCofinsAutocomplete
                                    readOnly={(Boolean(Object.keys(cstPisSaida).length) || show)}
                                    open={openCstPisSaida}
                                    value={cstPisSaida}
                                    onChange={(event, value) => {
                                        if(value !== null) setCstPisSaida(value)
                                    }}
                                    onBlur={handleOnBlur}
                                    inputValue={searchCstPisSaida}
                                    onInputChange={(event, value) => {
                                        if(value === cstPisSaida.titulo) setSearchPisSaida(value);

                                        if(value !== undefined && value !== ' ' && value !== cstPisSaida.titulo) {
                                            setSearchPisSaida(value);
                                            setOpenCstPisSaida(true);
                                        };

                                        if(value === '') {
                                            setCstPisSaida('')
                                            setSearchPisSaida('');
                                            setOpenCstPisSaida(false);
                                        };
                                    }}
                                    noOptionsText={"Não foram encontrado resultados"}
                                    getOptionLabel={(option) => option.titulo ? option.titulo : ''}
                                    isOptionEqualToValue={(option, value) => value.id === undefined || option.id === value.id}
                                    options={optionsCstPisSaida}
                                    loading={loadingCstPisSaida}
                                    loadingText={"Carregando..."}
                                    onClose={() => setOpenCstPisSaida(false)}
                                    renderInput={(params) =>  {
                                        return (
                                            <TextField
                                                name={"cstPisSaida"}
                                                variant="standard"
                                                error={cstPisSaidaError}
                                                {...params}
                                                onFocus={handleOnFocus}
                                                margin="dense"
                                                required
                                                size="small"
                                                label="CST PIS/COFINS Saída"
                                                placeholder="Digite o nome ou código"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <Box sx={{right:0, position:'absolute', display:'flex'}}>
                                                            {loadingCstPisSaida ? <CircularProgress color="inherit" size={16} /> : null}
                                                                <Box sx={{display:'flex'}}>
                                                                    {(Boolean(Object.keys(cstPisSaida).length) && !show) &&
                                                                        <IconButton
                                                                            sx={{
                                                                                display:'flex',
                                                                                flexDirection:"row"
                                                                            }}
                                                                            onClick={() => {
                                                                                setCstPisSaida('');
                                                                                setSearchPisSaida('');
                                                                                setNaturezaError(false);
                                                                            }}
                                                                            size={'small'}
                                                                        >
                                                                            <CloseIcon sx={{ fontSize: 20, color: '#f44336' }}/>
                                                                        </IconButton>
                                                                    }
                                                                </Box>
                                                        </Box>
                                                    ),
                                                }}
                                            />
                                        )
                                    }}
                                /> 
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ padding: '16px 24px', paddingTop: '0px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        {Boolean(errors.filter(x => x.error === true).length) && <ReportGmailerrorredIcon onClick={handleModalError} sx={{ fontSize: 32, color: '#f44336', cursor: 'pointer' }}/>}
                        <Box></Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                            <Button onClick={handleClose} sx={{ color: '#fff' }}>FECHAR</Button>
                            {!show && (<Button variant="contained" color="success" onClick={handleConfirmSave}>SALVAR</Button>)}
                        </Box>
                    </Box>
                </DialogActions>
                <Box sx={{ background: '#000000f2', height: '100%', width: '100%', position: 'absolute', zIndex: 9999, display: loadingImposto ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </Box>
            </Dialog>
            
            <Dialog fullWidth={true} maxWidth={'xs'} open={modalError}>
                <DialogTitle sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography>Alertas</Typography>
                    <CloseIcon sx={{ fontSize: 24, cursor: 'pointer' }} onClick={() => setModalError(false)}/>
                </DialogTitle>

                <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                    {errors.filter(x => x.error === true).map((value, index) => (
                        <Box key={index}>
                            <Typography sx={{ fontSize: 14, color: '#f44336' }}>{value.name}</Typography>
                            <Typography sx={{ fontSize: 11, fontStyle: 'italic' }}>{value.msg}</Typography>
                            <Typography sx={{ fontSize: 11, fontStyle: 'italic' }}>{value.example}</Typography>
                        </Box>
                    ))}
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}