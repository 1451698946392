export default {
    validar: async(params) => {
        return new Promise((resolve, reject) => {
            const index = [
                'ncm',
                'cest',
                'pis',
                'cofins',
                'natureza',
                'cst',
                'aliquota',
                'aliquotaFinal',
                'reducao',
                'fcp',
                'codbenef',
                'codinfo',
                'aliquotaDesoneracao'
            ];

            const pending = index.filter(x => !Object.keys(params).includes(x));
            
            if(Boolean(pending.length)) {
                return reject({ message: `Não foi possível copiar os dados do produto, entre em contato com o suporte. (${pending.join(', ')})` });
            };
        
            const ncm = String(params?.ncm).replace(/\D/g, '');
            if(ncm.length !== 8) {
                return reject({ message: `NCM com tamanho inválido: ${ncm}, não foi possível copiar os dados do produto, entre em contato com o suporte.` });
            };
        
            const cest = params?.cest.includes(' / ') ? params?.cest.split(' / ')[0].replace(/\D/g, '') : params?.cest.replace(/\D/g, '');
            if(cest.length !== 7 && cest.length > 0 && cest !== '') {
                return reject({ message: `CEST com tamanho inválido: ${cest}, não foi possível copiar os dados do produto, entre em contato com o suporte.`});
            };
        
            const pis = Number(String(params?.pis).replace(/\D/g, ''));
            if(pis === '' || isNaN(pis)) {
                return reject({ message: `PIS inválido: ${pis}, não foi possível copiar os dados do produto, entre em contato com o suporte.` });
            };
        
         
            const cofins = Number(String(params?.cofins).replace(/\D/g, ''));
            if(cofins === '' || isNaN(cofins)) {
                return reject({ message: `COFINS inválido: ${cofins}, não foi possível copiar os dados do produto, entre em contato com o suporte.` });
            };
        
            const cst = ['000', '020', '040', '041', '060', '090'].includes(params?.cst) ? params?.cst : null;
            if(!cst) {
                return reject({ message: `CST inválido: ${cst}, não foi possível copiar os dados do produto, entre em contato com o suporte.` });
            };
        
            const codbenef = params?.codbenef === null ? null : params?.codbenef.length > 0 ? params?.codbenef : null;
            if(codbenef !== null) {
                if(codbenef.length !== 8) {
                    return reject({ message: `Código de Benefício inválido: ${codbenef}, não foi possível copiar os dados do produto, entre em contato com o suporte.` });
                };
            };

            const codinfo =  params?.codinfo === null ? null : params?.codinfo.length > 0 ? params?.codinfo : null;
            if(codinfo !== null) {
                if(codinfo.length !== 10 && codinfo.length !== null) {
                    return reject({ message: `Código Informativo inválido: ${codinfo}, não foi possível copiar os dados do produto, entre em contato com o suporte.` });
                };
            };

            return resolve(params);
        });
    }
};