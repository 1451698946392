export const Lojas = [
    {
        nome: 'Embapel Loja 6',
        endereco: 'Avenida Independencia, S/N',
        regiao: 'Unamar - Cabo Frio/RJ',
        local: 'UNAMAR - CABO FRIO',
        complemento: 'Quadra 1 Lote 4',
        cep: '28928-542',
        telefone: '(22) 2630-8461',
        horario: '2ª feira a Sábado, das 7h às 22h',
        domingo: 'Domingo, 8h às 18h',
        whatsapp: [
            {
                nome: 'CANAL DE VENDAS CENTRAL',
                link: 'https://api.whatsapp.com/send/?phone=5522997736239&text&type=phone_number&app_absent=0'
            },
            {
                nome: 'CANAL DE VENDAS LOCAL',
                link: 'https://api.whatsapp.com/send/?phone=5522981574528&text&type=phone_number&app_absent=0'
            }
        ]
    },
    {
        nome: 'Embapel Loja 7',
        endereco: 'Rua Comandante Arnaldo Faria da Silva, 4',
        regiao: 'Centro - São Pedro da Aldeia/RJ',
        local: 'SÃO PEDRO DA ALDEIA',
        complemento: 'Quadra C',
        cep: '28941-160',
        telefone: '(22) 2627- 6283',
        horario: '2ª feira a Sábado, das 7h às 20h',
        domingo: 'Domingo, 8h às 18h',
        whatsapp: [
            {
                nome: 'CANAL DE VENDAS ONLINE',
                link: 'https://api.whatsapp.com/send/?phone=552226276283&text&type=phone_number&app_absent=0'
            }
        ]
    },
    {
        nome: 'Embapel Loja 8',
        endereco: 'Avenida Amaral Peixoto, 696',
        regiao: 'Barra de São joão Casimiro de Abreu/RJ',
        local: 'BARRA - CASIMIRO DE ABREU',
        complemento: '2º Distrito',
        cep: '28880-000',
        telefone: '(21) 2238-1198',
        horario: '2ª feira a Sábado, das 7h às 21h',
        domingo: 'Domingo, 8h às 18h',
        whatsapp: []
    },
    {
        nome: 'Embapel Loja 9',
        endereco: 'Avenida América Central, 1177',
        regiao: 'São Cristovão - Cabo Frio/RJ',
        local: 'SÃO CRISTOVÃO - CABO FRIO',
        complemento: 'Lote 14 Quadra T',
        cep: '28909-581',
        telefone: '(22) 2630-9341',
        horario: '2ª feira a Sábado, das 7h às 20h',
        domingo: 'Domingo, 8h às 18h',
        whatsapp: [
            {
                nome: 'CANAL DE VENDAS ONLINE',
                link: 'https://api.whatsapp.com/send/?phone=5522998538824&text&type=phone_number&app_absent=0'
            }
        ]
    },
    {
        nome: 'Embapel Loja 10',
        endereco: 'Rua Valdeci Barros de Farias, 228',
        regiao: 'Nova Aliança - Rio das Ostras/RJ',
        local: 'ALIANÇA - RIO DAS OSTRAS',
        complemento: '',
        cep: '28893-190',
        telefone: '(22) 2630-9343',
        horario: '2ª feira a Sábado, das 7h às 21h',
        domingo: 'Domingo, 8h às 18h',
        whatsapp: [
            {
                nome: 'CANAL DE VENDAS ONLINE',
                link: 'https://api.whatsapp.com/send/?phone=5522988586293&text&type=phone_number&app_absent=0'
            }
        ]
    },
    {
        nome: 'Embapel Loja 11',
        endereco: 'Avenida Independencia, 71',
        regiao: 'Unamar (Tamoios) - Cabo Frio/RJ',
        local: 'TAMOIOS - CABO FRIO',
        complemento: '',
        cep: '28928-542',
        telefone: '(22) 2630-9342',
        horario: '2ª feira a Sábado, das 7h às 20h',
        domingo: 'Domingo, 8h às 18h',
        whatsapp: []
    },
    {
        nome: 'Embapel Loja 12',
        endereco: 'Avenida Nossa Senhora da Conceição, S/N',
        regiao: 'Village Rio das Ostras - Rio das Ostras/RJ',
        local: 'VILLAGE - RIO DAS OSTRAS',
        complemento: 'Lote 5 Quadra 1',
        cep: '28895-602',
        telefone: '(21) 2630-9341',
        horario: '2ª feira a Sábado, das 7h às 21h',
        domingo: 'Domingo, 8h às 18h',
        whatsapp: [
            {
                nome: 'CANAL DE VENDAS ONLINE',
                link: 'https://api.whatsapp.com/send/?phone=5522988586293&text&type=phone_number&app_absent=0'
            }
        ]
    },
];