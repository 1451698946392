import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Box, Container } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import { styled } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FolderCopy from '@mui/icons-material/FolderCopy';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import OutlinedInput from '@mui/material/OutlinedInput';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert2'
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import './index.css';

import { useTheme } from '@mui/material';
import { useAuth } from '../../contexts/auth';

import Order from '../../components/Order';
import Impostos from '../../components/Impostos';

import Server from '../../api/Server';
import Getway from '../../api/Getway';
import LayoutAdmin from '../../components/Layout/LayoutAdmin';

function useWidth() {
    const theme = useTheme();
    const keys = [...theme.breakpoints.keys].reverse();
    return (
        keys.reduce((output, key) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const matches = useMediaQuery(theme.breakpoints.up(key));
            return !output && matches ? key : output;
        }, null) || 'xs'
    );
};

const headCells = [
    {
        id: 'pedidoId',
        align: 'center',
        disablePadding: true,
        label: 'Nº Pedido',
        labelMin: 'Nº Pedido',
        width: '100px',
        display: 'table-cell'
    },
    {
        id: 'lojaId',
        align: 'center',
        disablePadding: true,
        label: 'Loja',
        labelMin: 'Loja',
        width: '100px',
        display: 'table-cell'
    },
    {
        id: 'razao',
        align: 'center',
        disablePadding: true,
        label: 'Cliente',
        labelMin: 'Cliente',
        width: '400px',
        display: 'table-cell'
    },
    {
        id: 'data',
        align: 'center',
        disablePadding: true,
        label: 'Data do Pedido',
        labelMin: 'Data',
        width: '140px',
        display: 'none'
    },
    {
        id: 'valor',
        align: 'center',
        disablePadding: true,
        label: 'Valor',
        labelMin: 'Valor',
        width: '140px',
        display: 'none'
    },
    {
        id: 'situacao',
        align: 'center',
        disablePadding: true,
        label: 'Situação',
        labelMin: 'Situação',
        display: 'table-cell'
    }
];

function HeadCellName({name}) {
    const width = useWidth();
    return (width === 'xs' || width === 'sm' || width === 'md') ? name[1] : name[0];
};

const LabelDisplayPagination = ({dados}) => {
    const width = useWidth();
    return (width === 'xs' || width === 'sm') ? <label style={{fontSize:'10px'}}>{dados}</label> : <label style={{fontSize:'14px'}}>{dados}</label>;
};

const LabelRowsPerPage = () => {
    const width = useWidth();
    return (width === 'xs' || width === 'sm') ? <label style={{fontSize:'10px'}}>Linhas por página:</label> : <label style={{fontSize:'14px'}}>Linhas por página:</label>;
};
 
//Componente com cabeçalho da Tabela
function EnhancedTableHead(props) {
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sx={{
                            width:headCell.width,
                            display:{lg:'table-cell', md:'table-cell', sm:'table-cell', xs:headCell.display},
                            fontSize:{lg:'14px', md:'14px', sm:'14px', xs:'10px'}
                        }}
                    >
                        <HeadCellName name={[headCell.label, headCell.labelMin]}/>
                    </TableCell>
                ))}
                <TableCell sx={{width: '155px'}} key={"acoes"} align="center">Ações</TableCell>
            </TableRow>
        </TableHead>
    );
};

const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 550,
            padding: 0,
        },
    },
};

const CustomTextField = styled(TextField)(({ theme }) => ({
    minWidth: '160px',
    '& label': {
        '&.Mui-focused': {
            color: '#888dd1'
        },
        '&.Mui-error': {
            color: '#ff9397',
            borderColor: '#ff9397'
        },
    },
    '& .MuiInputBase-input': {
        '&.Mui-error': {
            color: '#f6f6f6',
            borderColor: '#ff9397'
        },
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#888dd1',
        },
        '&.Mui-error': {
            color: '#f6f6f6',
            '& fieldset': {
                borderColor: '#ff9397',
            }
        },
    },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
    '& label': {
        '&.Mui-focused': {
            color: '#888dd1'
        },
        '&.Mui-error': {
            color: '#ff9397',
            borderColor: '#ff9397'
        },
    },
    '& .MuiInputBase-input': {
        '&.Mui-error': {
            color: '#f6f6f6',
            borderColor: '#ff9397'
        },
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: '#888dd1',
        },
        '&.Mui-error': {
            color: '#f6f6f6',
            '& fieldset': {
                borderColor: '#ff9397',
            }
        },
    },
}));

export default function Pagonet() {
    const searchRef = useRef(null);
    const theme = useTheme();
    const { getwayAllStores, getwayStore, getway, setBackdrop, signed } = useAuth();
    
    const [loading, setLoading] = React.useState(false);
    const [loadingImposto, setLoadingImposto] = useState(false);
    const [openOrder, setOpenOrder] = useState(false);

    //Paginação e ordenação da Tabela
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => setPage(newPage);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const ContainerTable = () => {
        return (
            Boolean(orders.length) && (
                <Box sx={{ flexGrow: 1 }}>
                    <TableContainer>
                        <Table>
                            <EnhancedTableHead order={order}/>

                            <TableBody>
                                {orders
                                    .slice(page * rowsPerPage, rowsPerPage === -1 ? orders.lenght : page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        return (
                                            <TableRow hover tabIndex={-1} key={index}>
                                                <TableCell align="center" sx={{ padding: '0px 10px 0px 10px', fontSize: { lg: '14px', md: '14px', sm: '12px', xs: '10px' }}}>
                                                    {row?.pedidoId}
                                                </TableCell>

                                                <TableCell align="center" sx={{ padding: '0px 10px 0px 10px', fontSize: { lg: '14px', md: '14px', sm: '12px', xs: '10px' }}}>
                                                    {row.lojaId}
                                                </TableCell>

                                                <TableCell align="left" sx={{ padding: '0px 10px 0px 0px', fontSize: { lg: '14px', md: '14px', sm: '12px', xs: '10px' }}}>
                                                    <Box sx={{ overflow: 'hidden', display: '-webkit-box', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical' }} title={row.razao}>
                                                        {row.clienteId} - {row.razao}
                                                    </Box>
                                                </TableCell>

                                                <TableCell align="center" padding="none" sx={{ display: { lg: 'table-cell', md: 'table-cell', sm: 'table-cell', xs: 'none' }}}>
                                                    {moment(row.cadastro).format('DD/MM/YYYY')}
                                                </TableCell>

                                                <TableCell align="center" padding="none" sx={{ display: { lg: 'table-cell', md: 'table-cell', sm: 'table-cell', xs: 'none' }}}>
                                                    {row.valorFormatado}
                                                </TableCell>

                                                <TableCell align="center" padding="none">
                                                    <Stack sx={{ justifyContent: 'center' }} direction="row">
                                                        {(row.situacao === 'AB') && (
                                                            <Box title={"Aguardando transferência"} sx={{ display: 'flex', cursor: 'help' }}>
                                                                <PendingOutlinedIcon  sx={{ color: '#e9e7e6', fontSize: { lg: '28px', md: '24px', sm: '24px', xs: '18px' }}}/>
                                                            </Box>
                                                        )}
                                                        
                                                        {((row.situacao === 'NF') || (row.situacao === 'BX') || (row.situacao === 'PD')) && (
                                                            <Box title={"Nota fiscal transferida"} sx={{ display: 'flex', cursor: 'help' }}>
                                                                <CheckCircleOutlinedIcon sx={{ color: '#95c97b',  fontSize: { lg: '28px', md: '24px', sm: '24px', xs: '18px' }}}/>
                                                            </Box>
                                                        )}

                                                        {((row.situacao === 'CA')) && (
                                                            <Box title={"O pedido está cancelado"} sx={{ display: 'flex', cursor: 'help' }}>
                                                                <DoDisturbIcon sx={{ color: theme.custom.color.cancel,  fontSize: { lg: '28px', md: '24px', sm: '24px', xs: '18px' }}}/>
                                                            </Box>
                                                        )}
                                                    </Stack>
                                                </TableCell>

                                                <TableCell align="center" padding="none">
                                                    <Stack sx={{ justifyContent: 'center' }} direction="row">
                                                        <IconButton
                                                            title="Classificar tributações"
                                                            onClick={(e) => (!Boolean(Object.keys(order).length)) && handleModalOrderOpen(row.pedidoId)}
                                                            color="secondary"
                                                            disabled={openOrder || (row.situacao === 'CA') || (row.situacao === 'BX') || (row.situacao === 'NF') || (row.situacao === 'PD')}
                                                        >
                                                            <FolderCopy sx={{ color: (row.situacao === 'CA') || (row.situacao === 'BX') || (row.situacao === 'NF') || (row.situacao === 'PD') ? '#3d3d3d' : '#e99d67', fontSize: { lg: '28px', md: '24px', sm: '24px', xs: '14px' }}} />
                                                        </IconButton>
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }

                            </TableBody>
                        </Table>
                    </TableContainer>
                    
                    <TablePagination
                        rowsPerPageOptions={[10, 30, 60, { label: 'Todos', value: -1 }]}
                        component="div"    
                        count={orders.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={<LabelRowsPerPage/>}
                        sx={{ fontSize: { lg: '14px', md: '14px', sm: '14px', xs: '10px' }}}
                        labelDisplayedRows={ (from=page) => <LabelDisplayPagination dados={`${from.from}-${from.to === -1 ? from.count : from.to} de ${from.count}`}/>}
                    />
                </Box>
            )
        )
    };

    const [stores, setStores] = useState([]);
    const [selectStores, setSelectStores] = React.useState([]);
    const [numberOrder, setNumberOrder] = useState('');
    const [numberOrderError, setNumberOrderError] = useState(false);
    
    const [orders, setOrders] = useState([]);
    const [order, setOrder] = useState([]);
    const [firstSearch, setFirstSearch] = useState(true);
    const [empty, setEmpty] = useState(false);

    const [product, setProduct] = useState({});

    const [modalOrder, setModalOrder] = useState(false);
    const [modalImposto, setModalImposto] = useState(false);

    const [cancel, setCancel] = useState([]);

    const [edit, setEdit] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {
        const options = [{
            "razao": "Desmarcar todas as lojas",
            "fantasia": "Desmarcar as lojas"
        }];

        getwayAllStores.map(x => options.push({ razao: `${x.lojaId} - ${x.razao}`, fantasia: `${x.lojaId}` }));

        setStores(options);

        setSelectStores(getwayAllStores.map(item => String(item.lojaId)));
    },[getwayAllStores]);

    useEffect(() => {
        (async() => {
            const { data } = await Server.getOrderPending({ cnpj: getwayStore.cnpj })
            if(Boolean(data?.length)) await handleModalOrderOpen(data[0].pedido_id);
        })();
    },[]);

    // Ação para alterar as lojas selecionadas
    const handleChangeStore = (event) => {
        const { target: { value } } = event;

        if(value.includes(value[value.length-1].fantasia)) {
            value.splice(value.indexOf(value[value.length-1].fantasia), 1);
            value.pop();
        };

        if(Boolean(value.length)) {
            if(value[value.length-1].fantasia === 'Todas as lojas') {
                const temp = [ ...stores ];
                temp.shift();

                const tempOptions = [ ...stores ];
                tempOptions[0] = {
                    "razao": "Desmarcar todas as lojas",
                    "fantasia": "Desmarcar as lojas"
                }
                setStores(tempOptions);
                return setSelectStores(temp.map(x => x.fantasia));
            };

            if(value[value.length-1].fantasia === 'Desmarcar as lojas') {
                const tempOptions = [ ...stores ];
                tempOptions[0] = {
                    razao: 'Selecionar todas as lojas',
                    fantasia: 'Todas as lojas'
                };
                setStores(tempOptions);
                return setSelectStores([String(getway?.codLoja)])
            };

            if(value[value.length-1].fantasia !== 'Todas as lojas' && value.includes('Todas as lojas')) value.splice(value.indexOf('Todas as lojas'), 1);

            const array = value.map(x => typeof x === 'object' ? x.fantasia : x).sort((a, b) => Number(a) - Number(b));
            setSelectStores(typeof array === 'string' ? array.split(',') : array);
        };
    };

    // Ação para buscar o pedido no Samb@net
    const handleSearchOrder = async() => {
        try {
            if(numberOrder === '' || Number(numberOrder) === 0) {
                setNumberOrderError(true);
                return searchRef.current.focus();
            };

            setLoading(true);
            setEmpty(false);
    
            const ids = selectStores.map(x => Number(x.replace(/\D/g, '')));
    
            const params = {
                "paginacao": {
                    "paginaAtual": 0,
                    "itensPorPagina": 10,
                    "colunaOrdenacao": "pedidoId",
                    "direcaoOrdenacao": ""
                },
                "lojaIds": ids,
                "pedidoId": Number(numberOrder),
                "periodoCadastro": null,
                "clienteId": null,
                "situacao": "",
                "vendedorId": null,
                "semRomaneio": false
            };
    
            const { data: { itens }} = await Getway.searchOrder(params, getway?.token);
            Boolean(itens?.length) ? setOrders([ ...itens ]) : setEmpty(true);

            setFirstSearch(false);
        } catch(err) {
            console.log('Pagonet, handleSearchOrder:', err.debugger || err.message);

            Swal.fire({
                title: `<label style="font-size:18px;font-family:Muli">${'Atenção'}</label>`,
                html: `<label style="font-size:14px;font-family:Muli">${'Não foi possível consultar as tributações dos produtos do pedido, caso persista entre em contato com suporte do aplicativo'}</label>`,
                icon: 'error',
                width: '30em',
                confirmButtonColor: theme.palette.primary.main,
                confirmButtonText: `<label style="font-size:14px;font-family:Muli">CONCLUIR</label>`
            });
        } finally {
            setLoading(false);
        };
    };

    // Ação para abrir Modal do Pedido
    const handleModalOrderOpen = async(value) => {
        try {
            setBackdrop(true);
            setOpenOrder(true);

            const { data: { capa, itens } } = await Getway.searchOrderData(value, getway?.token);
            const paramsOrder = {
                capa,
                itens: []
            };

            const { data: dataServer } = await Server.getOrder({ cnpj: getwayStore.cnpj, pedidoId: capa?.pedidoId });

            Object.assign(paramsOrder, { pedido: (dataServer !== null) ? [ dataServer ] : [ { status: 0 } ] });
    
            for(let i=0;i<itens?.length;i++) {
                await new Promise(async resolve => {
                    try {
                        const params = {
                            cnpj: getwayStore.cnpj,
                            prodId: itens[i].prodId,
                            pedidoId: value
                        };

                        const { data: dataFgf } = await Server.getProduct(params);

                        paramsOrder.itens.push({
                            ...itens[i],
                            fgf: [{
                                old: dataFgf?.length ? [ JSON.parse(dataFgf[0]?.old) ] : [  ],
                                new: dataFgf?.length ? [ JSON.parse(dataFgf[0]?.new) ] : [  ]
                            }],
                        });
                    } catch(err) {
                        console.log('Carregando configurações do FGF no Servidor:', err.debugger || err.message);

                        Swal.fire({
                            title: `<label style="font-size:18px;font-family:Muli">${'Atenção'}</label>`,
                            html: `<label style="font-size:14px;font-family:Muli">Falha em consultar tributação no Servidor, caso persista entre em contato com suporte do aplicativo</label>`,
                            icon: 'error',
                            width: '25em',
                            confirmButtonColor: theme.palette.primary.main,
                            confirmButtonText: `<label style="font-size:14px;font-family:Muli">CONCLUIR</label>`
                        });
                    } finally {
                        return resolve();
                    };
                });
            };
    
            if(dataServer?.cancel !== null && dataServer?.cancel !== '' && dataServer?.cancel !== undefined) {
                setCancel(dataServer?.cancel.split(', ').map(x => Number(x)))
            };

            setOrder(paramsOrder);
            setModalOrder(true);
        } catch(err) {
            console.log('Carregando configurações do FGF no Servidor:', err.debugger || err.message);

            Swal.fire({
                title: `<label style="font-size:18px;font-family:Muli">${'Atenção'}</label>`,
                html: `<label style="font-size:14px;font-family:Muli">Falha em consultar tributação no Servidor, caso persista entre em contato com suporte do aplicativo</label>`,
                icon: 'error',
                width: '25em',
                confirmButtonColor: theme.palette.primary.main,
                confirmButtonText: `<label style="font-size:14px;font-family:Muli">CONCLUIR</label>`
            });
        } finally {
            setBackdrop(false);
            setOpenOrder(false);
        };
    };

    const handleModalOrderClose = () => {
        setCancel([]);
        setModalOrder(false);
        setOrder([]);
    };

    const handleModalImpostoOpen = async(value) => {
        try {
            let response = (await Getway.getProduct(value.prodId, getway?.token)).data;
            response = { ...response, fgf: value.fgf };

            setProduct(response)
            setModalImposto(true);
        } catch(err) {
            console.log('Abrindo imposto:', err.debugger || err.message);

            Swal.fire({
                title: `<label style="font-size:18px;font-family:Muli">${'Atenção'}</label>`,
                html: `<label style="font-size:14px;font-family:Muli">${err.message.includes('JSON') ? 'Falha em abrir produto, caso persista entre em contato com suporte do aplicativo' : err.message}</label>`,
                icon: 'error',
                width: '30em',
                confirmButtonColor: theme.palette.primary.main,
                confirmButtonText: `<label style="font-size:14px;font-family:Muli">CONCLUIR</label>`
            });
        };
    };

    const handleModalImpostoClose = () => setModalImposto(false);
    
    const handleCancel = (value, check) =>{
        if(check) if(!cancel.includes(value)) setCancel(prev => [ ...prev, value ]);
        if(!check) setCancel(prev => [ ...prev.filter(x => x !== value) ]);
    };

    return (
        <LayoutAdmin>
            <Container maxWidth="md" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <Paper sx={{ backgroundColor: '#373c78', backgroundImage: 'none', borderTopLeftRadius: 12, borderTopRightRadius: 12, borderBottomLeftRadius: { xs: 0, sm: '4px' }, borderBottomRightRadius: { xs: 0, sm: '4px' } }}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', padding: '20px 16px 20px 16px', gap: '15px' }}>
                        <FormControl sx={{ minWidth: 200, flex: 1 }} >
                            <InputLabel>Selecionar</InputLabel>
                            <CustomSelect
                                multiple
                                value={selectStores}
                                onChange={handleChangeStore}
                                input={<OutlinedInput label="Selecionar" />}
                                renderValue={(selected) => 'Loja(s): '+selected.join(', ')}
                                MenuProps={MenuProps}
                                size='small'
                            >
                                {stores.map((name) => (
                                    <MenuItem key={name.fantasia} value={name}>
                                        {name.fantasia !== 'Todas as lojas' && name.fantasia !== 'Desmarcar as lojas' && (
                                            <Checkbox checked={selectStores.indexOf(name.fantasia) > -1} />
                                        )}
                                        <ListItemText sx={{ fontSize: 10 }} primary={name.razao} />
                                    </MenuItem>
                                ))}
                            </CustomSelect>
                        </FormControl>
                    
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', flex: 1, gap: 1 }}>
                            <CustomTextField
                                inputRef={searchRef}
                                value={numberOrder}
                                onChange={(event) => {
                                    setNumberOrder(event.target.value.replace(/[^0-9]/g, ''))
                                    setNumberOrderError(false);
                                }}
                                onBlur={() => setNumberOrderError(false)}
                                label="Número do pedido"
                                placeholder="Digite o número do pedido"
                                variant="outlined"
                                size='small'
                                onKeyDown={(event) => (event.key === 'Enter') && handleSearchOrder()}
                                fullWidth
                                error={numberOrderError}
                                autoComplete='off'
                            />
                            <Button 
                                variant="contained"
                                sx={{ backgroundColor: '#2392ba', color: '#f6f6f6' }}
                                size='small'
                                onClick={handleSearchOrder}
                            >
                                BUSCAR
                            </Button>
                        </Box>
                    </Box>
                </Paper>

                <Paper sx={{ flexGrow: 1, display: 'flex', mt: { md: 1, xs: 0 }, minHeight: 150, backgroundColor: '#373c78', borderBottomLeftRadius: 12, borderBottomRightRadius: 12, borderTopRightRadius: { xs: 0, sm: '4px' }, borderTopLeftRadius: { xs: 0, sm: '4px' } }}>
                    {loading
                        ? (
                            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <CircularProgress />
                            </Box>
                        ) : (firstSearch
                            ? (
                                <Box sx={{ flexGrow: 1, padding: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                                    <Typography>Selecione a loja e insira o número do pedido,</Typography>
                                    <Typography>após isso faça a pesquisa para localizá-lo</Typography>
                                </Box>
                            ) : (!empty
                                ? (
                                    <ContainerTable/>
                                ) : (
                                    <Box sx={{ flexGrow: 1, padding: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                                        <Typography>Não encontramos resultados para sua busca.</Typography>
                                        <Typography>Altere os filtros e tente novamente.</Typography>
                                    </Box>
                                )
                            )
                        )
                    }       
                </Paper>

                <Order
                    closeModal={handleModalOrderClose}
                    order={order}
                    open={modalOrder}
                    modalImposto={modalImposto}
                    openModalImposto={handleModalImpostoOpen}
                    handleCancel={handleCancel}
                    handleModalOrderOpen={handleModalOrderOpen}
                    cancel={cancel}
                    edit={edit}
                    setEdit={setEdit}
                    show={show}
                    setShow={setShow}
                />
                <Impostos
                    closeModal={handleModalImpostoClose}
                    order={order}
                    open={modalImposto}
                    loadingImposto={loadingImposto}
                    setLoadingImposto={setLoadingImposto}
                    handleModalOrderOpen={handleModalOrderOpen}
                    cancel={cancel}
                    setCancel={setCancel}
                    edit={edit}
                    setEdit={setEdit}
                    show={show}
                    product={product}
                />
            </Container>
        
        </LayoutAdmin>
    );
};
