import axios from 'axios';

const api = axios.create({ baseURL: 'https://sambanet.getway.com.br/rest/api' });
const apiReport = axios.create({ baseURL: 'https://www.sambanet.net.br/sambanet/gwrelatorio/api' });

export default {
    login: (params) => {
        return new Promise(async(resolve, reject) => {
            await api.post('/login/token', params, { timeout: 30000})
            .then(response => {
                return resolve(response);
            }).catch(err => {
                console.log(err.code)
                if(err.code === 'ECONNABORTED') return reject({ message: 'Não foi possível conectar no Samb@net no tempo limite de consulta, entre contato contato com suporte do aplicativo', debugger: err.message });
                if(err.code === 'ERR_NETWORK') return reject({ message: 'Não foi possível conectar no Samb@net, verifique sua conexão com a internet', debugger: err.message });

                return reject({ message: err.response?.data.Message || err.message, debugger: err.response });
            });
        });
    },
    refreshLogin: (token) => {
        return new Promise(async(resolve, reject) => {
            await api.get('/login/obterAtualizacaoDadosLogin',
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    timeout: 30000
                }
            )
            .then(response => {
                return resolve(response);
            }).catch(err => {
                if(err.code === 'ECONNABORTED') return reject({ message: 'Não foi possível conectar no Samb@net no tempo limite de consulta, entre contato contato com suporte do aplicativo', debugger: err.message });
                if(err.code === 'ERR_NETWORK') return reject({ message: 'Não foi possível conectar no Samb@net, verifique sua conexão com a internet', debugger: err.message });

                return reject({ message: 'Não foi possível validar suas credênciais, efetue o acesso novamente no aplicativo e caso persista entre em contato com suporte.', debugger: err.response });
            });
        }); 
    },
    loginReport: (params) => {
        return new Promise(async(resolve, reject) => {
            await apiReport.post('/token', params, { timeout: 12000})
            .then(response => {
                return resolve(response);
            }).catch(err => {
                console.log(err.code)
                if(err.code === 'ECONNABORTED') return reject({ message: 'Não foi possível conectar no Samb@net no tempo limite de consulta, entre contato contato com suporte do aplicativo', debugger: err.message });
                if(err.code === 'ERR_NETWORK') return reject({ message: 'Não foi possível conectar no Samb@net, verifique sua conexão com a internet', debugger: err.message });

                return reject({ message: err.response?.data.Message || err.message, debugger: err.response });
            });
        });
    },
    refreshReport: (token) => {
        return new Promise(async(resolve, reject) => {
            await apiReport.get('/api/account/profile',
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    timeout: 10000
                }
            )
            .then(response => {
                return resolve(response);
            }).catch(err => {
                if(err.code === 'ECONNABORTED') return reject({ message: 'Não foi possível conectar no Samb@net no tempo limite de consulta, entre contato contato com suporte do aplicativo', debugger: err.message });
                if(err.code === 'ERR_NETWORK') return reject({ message: 'Não foi possível conectar no Samb@net, verifique sua conexão com a internet', debugger: err.message });

                return reject({ message: 'Não foi possível validar suas credênciais, efetue o acesso novamente no aplicativo e caso persista entre em contato com suporte.', debugger: err.response });
            });
        }); 
    },
    search: (options, token) => {
        return new Promise(async(resolve, reject) => {
            await api.post('/buscaRapida/buscar', options,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                },
            )
            .then(response => {
                return resolve(response);
            }).catch(err => {
                return reject(err.response);
            });
        }); 
    },
    searchOrder: (params, token) => {
        return new Promise(async(resolve, reject) => {
            await api.post('/orcamentopedido/pedido/buscarPedidos', params,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                },
            )
            .then(response => {
                return resolve(response);
            }).catch(err => {
                if(err.code === 'ECONNABORTED') return reject({ message: 'Não foi possível conectar no Samb@net no tempo limite de consulta, entre contato contato com suporte do aplicativo', debugger: err.message });
                if(err.code === 'ERR_NETWORK') return reject({ message: 'Não foi possível conectar no Samb@net, verifique sua conexão com a internet', debugger: err.message });

                return reject({ message: 'Não foi possível consultar os dados no Samb@net, entre em contato com suporte do aplicativo!', debugger: err.response });
            });
        }); 
    },
    searchOrderData: (id, token) => {
        return new Promise(async(resolve, reject) => {
            await api.get(`/orcamentopedido/pedido/obterPedido?pedidoId=${id}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                },
            )
            .then(response => {
                return resolve(response);
            }).catch(err => {
                if(err.code === 'ECONNABORTED') return reject({ message: 'Não foi possível conectar no Samb@net no tempo limite de consulta, entre contato contato com suporte do aplicativo', debugger: err.message });
                if(err.code === 'ERR_NETWORK') return reject({ message: 'Não foi possível conectar no Samb@net, verifique sua conexão com a internet', debugger: err.message });

                return reject({ message: 'Não foi possível consultar os dados no Samb@net, entre em contato com suporte do aplicativo!', debugger: err.response });
            });
        }); 
    },
    searchAliqIcms: (params, token) => {
        return new Promise(async(resolve, reject) => {
            await api.post('/cadastro/produtos/aliquotafiscal/buscarAliquotaFiscal', params,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                },
            )
            .then(response => {
                return resolve(response);
            }).catch(err => {
                if(err.code === 'ECONNABORTED') return reject({ message: 'Não foi possível conectar no Samb@net no tempo limite de consulta, entre contato contato com suporte do aplicativo', debugger: err.message });
                if(err.code === 'ERR_NETWORK') return reject({ message: 'Não foi possível conectar no Samb@net, verifique sua conexão com a internet', debugger: err.message });

                return reject({ message: 'Não foi possível consultar os dados no Samb@net, entre em contato com suporte do aplicativo!', debugger: err.response });
            });
        }); 
    },
    searchTributacao: (params, token) => {
        return new Promise(async(resolve, reject) => {
            await api.post('/cadastro/produtos/tributacao/buscarTributacao', params,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                },
            )
            .then(response => {
                return resolve(response);
            }).catch(err => {
                if(err.code === 'ECONNABORTED') return reject({ message: 'Não foi possível conectar no Samb@net no tempo limite de consulta, entre contato contato com suporte do aplicativo', debugger: err.message });
                if(err.code === 'ERR_NETWORK') return reject({ message: 'Não foi possível conectar no Samb@net, verifique sua conexão com a internet', debugger: err.message });

                return reject({ message: 'Não foi possível consultar os dados no Samb@net, entre em contato com suporte do aplicativo!', debugger: err.response });
            });
        }); 
    },
    getProduct: (id, token) => {
        return new Promise(async(resolve, reject) => {
            await api.get(`/cadastro/produtos/obterProduto/${id}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                },
                
            )
            .then(response => {
                return resolve(response);
            }).catch(err => {
                if(err.code === 'ECONNABORTED') return reject({ message: 'Não foi possível conectar no Samb@net no tempo limite de consulta, entre contato contato com suporte do aplicativo', debugger: err.message });
                if(err.code === 'ERR_NETWORK') return reject({ message: 'Não foi possível conectar no Samb@net, verifique sua conexão com a internet', debugger: err.message });

                return reject({ message: 'Não foi possível consultar os dados no Samb@net, entre em contato com suporte do aplicativo!', debugger: err.response });
            });
        }); 
    },
    updateProduct: (params, token) => {
        return new Promise(async(resolve, reject) => {
            await api.post('/cadastro/produtos/salvarProduto', params,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                },
            )
            .then(response => {
                return resolve(response);
            }).catch(err => {
                return reject(err.response);
            });
        }); 
    },

    getCategory: (params, token) => {
        return new Promise(async(resolve, reject) => {
            await api.post('/cadastro/produtos/obterResumoCategoria', params,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                },
            )
            .then(response => {
                return resolve(response);
            }).catch(err => {
                return reject({ message: 'Não foi possível consultar a categoria do produto no Samb@net, entre em contato com suporte do aplicativo!', debugger: err.response });
            });
        }); 
    },
    getInvoices: (params, token) => {
        return new Promise(async(resolve, reject) => {
            await api.post('/cadastro/produtos/obterResumoEntradasNf', params,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                },
            )
            .then(response => {
                return resolve(response);
            }).catch(err => {
                return reject({ message: 'Não foi possível consultar as Notas fiscais do produto no Samb@net, entre em contato com suporte do aplicativo!', debugger: err.response });
            });
        }); 
    },
    getClient: (id, token) => {
        return new Promise(async(resolve, reject) => {
            await api.get(`/cadastro/cliente/obterDados?clienteId=${id}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                },
            )
            .then(response => {
                return resolve(response);
            }).catch(err => {
                return reject({ message: 'Não foi possível consultar o cliente no Samb@net, entre em contato com suporte do aplicativo!', debugger: err.response });
            });
        }); 
    },
    getCep: (cep, token) => {
        return new Promise(async(resolve, reject) => {
            await api.get(`/cadastro/cliente/consultarEnderecoPorCEP?cep=${cep}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                },
            )
            .then(response => {
                return resolve(response);
            }).catch(err => {
                return reject({ message: 'Não foi possível consultar o CEP no Samb@net, entre em contato com suporte do aplicativo!', debugger: err.response });
            });
        });
    },
    getAddresses: (id, token) => {
        return new Promise(async(resolve, reject) => {
            await api.get(`/cadastro/cliente/buscarEnderecos?clienteId=${id}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                },
            )
            .then(response => {
                return resolve(response);
            }).catch(err => {
                return reject({ message: 'Não foi possível consultar o endereço no Samb@net, entre em contato com suporte do aplicativo!', debugger: err.response });;
            });
        });
    },
    setAddress: (itens, token) => {
        return new Promise(async(resolve, reject) => {
            await api.post('/cadastro/cliente/salvarEndereco', itens,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                },
            )
            .then(response => {
                return resolve(response);
            }).catch(err => {
                return reject({ message: 'Não foi possível cadastrar o endereço no Samb@net, entre em contato com suporte do aplicativo!', debugger: err.response });
            });
        });
    },
    getContacts: (id, token) => {
        return new Promise(async(resolve, reject) => {
            await api.get(`/cadastro/cliente/buscarContatos?clienteId=${id}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                },
            )
            .then(response => {
                return resolve(response);
            }).catch(err => {
                return reject({ message: 'Não foi possível consultar o contato no Samb@net, entre em contato com suporte do aplicativo!', debugger: err.response });
            });
        });
    },
    setContact: (itens, token) => {
        return new Promise(async(resolve, reject) => {
            await api.post('/cadastro/cliente/salvarContato', itens,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                },
            )
            .then(response => {
                return resolve(response);
            }).catch(err => {
                return reject({ message: 'Não foi possível cadastrar o contato no Samb@net, entre em contato com suporte do aplicativo!', debugger: err.response });
            });
        });
    },
    getProducts: (data, token) => {
        return new Promise(async(resolve, reject) => {
            await api.post('/cadastro/produtos/buscarProdutosVendaImportacao', data,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                },
            )
            .then(response => {
                return resolve(response);
            }).catch(err => {
                return reject({ message: 'Não foi possível consultar os produtos no Samb@net, entre em contato com suporte do aplicativo!', debugger: err.response });
            });
        });
    },
    setReplacement: (params, token) => {
        return new Promise(async(resolve, reject) => {
            await api.post('/compras/trocasCompras/salvarDados', params,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                },
            )
            .then(response => {
                return resolve(response);
            }).catch(err => {
                return reject({ message: 'Não foi possível cadastrar a troca no Samb@net, entre em contato com suporte do aplicativo!', debugger: err.response });
            });
        });
    },
};